import React from "react";
import BezierComponent from "./BezierComponent";

function range(from, to, step) {
  const t = [];
  for (let i = from; i < to; i += step) t.push(i);
  return t;
}

function sameShadowObject(a, b) {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) return false;
  for (let i in a) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export default class Grid extends BezierComponent {
  gridX(div) {
    var step = 1 / div;
    return range(0, 1, step).map(this.x);
  }

  gridY(div) {
    var step = 1 / div;
    return range(0, 1, step).map(this.y);
  }

  shouldComponentUpdate(nextProps) {
    if (super.shouldComponentUpdate(nextProps)) return true;
    const { background, gridColor, textStyle } = this.props;
    return (
      nextProps.background !== background ||
      nextProps.gridColor !== gridColor ||
      !sameShadowObject(nextProps.textStyle, textStyle)
    );
  }

  render() {
    const { x, y } = this;
    const { background, gridColor } = this.props;

    const sx = x(0);
    const sy = y(0);
    const ex = x(1);
    const ey = y(1);

    const xtenth = this.gridX(4);
    const ytenth = this.gridY(4);

    const gridbg = `M${sx},${sy} L${sx},${ey} L${ex},${ey} L${ex},${sy} Z`;

    const tenth = xtenth
      .map((xp) => `M${xp},${sy} L${xp},${ey}`)
      .slice(1)
      .concat(ytenth.map((yp) => `M${sx},${yp} L${ex - 1},${yp}`).slice(1))
      .join(" ");

    const half = `M${sx},${sy} L${ex},${ey}`;

    return (
      <g>
        <path fill={background} d={gridbg} />
        <path strokeWidth="1px" stroke={gridColor} d={tenth} />
        <path
          strokeWidth="1px"
          stroke={gridColor}
          d={half}
          strokeDasharray="10, 15"
        />
      </g>
    );
  }
}
