import React, { memo } from "react";
import { Copy } from "./Copy";
import { styled, css } from "./helpers/theme";
import { Icon, IconNames, IconColors } from "./Icon";

type ButtonModes = "button" | "radio" | "tiny";

type Props = {
  mode?: ButtonModes;
  children?: any;
  onClick?: any;
  primary?: boolean;
  icon?: IconNames;
  iconColor?: IconColors;
  active?: boolean;
  disabled?: boolean;
  tiny?: boolean;
  style?: any;
  fullWidth?: boolean;
  gradient?: boolean;
};

const isGradient = (props: any) => `
  fill: ${
    props.gradient ? "url(#gradient)" : props.theme.colors.baseWhite
  } !important;
`;

export const Button = memo(
  ({
    children,
    mode = "radio",
    icon,
    iconColor,
    primary,
    active,
    disabled,
    tiny,
    fullWidth,
    gradient,
    ...rest
  }: Props) => {
    return (
      <ButtonInitial
        primary={primary}
        active={active && !disabled}
        disabled={disabled}
        onlyIcon={!!children}
        mode={mode}
        fullWidth={fullWidth}
        gradient={gradient}
        {...rest}
      >
        {icon && <Icon name={icon} size={30} />}
        <Copy type="span" display="label">
          {children}
        </Copy>
      </ButtonInitial>
    );
  },
  (prev, next) =>
    prev.children === next.children &&
    prev.onClick === next.onClick &&
    prev.active === next.active &&
    prev.disabled === next.disabled &&
    prev.icon === next.icon
);

const ButtonInitial = styled.button<Props & { onlyIcon: boolean }>`
  height: 40px;
  width: auto;
  padding: 0 10px;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid transparent;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  align-self: flex-start;
  span {
    white-space: nowrap;
  }

  &:hover {
    .icon {
      ${(props) => isGradient(props)}
    }
  }

  &:active {
    border: 1px solid ${(props) => props.theme.colors.baseWhite};
    ${(props) => props.theme.screen.mobile} {
      border: 1px solid ${(props) => props.theme.colors.baseGrey4};
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .icon {
    margin-right: ${(props) => (props.onlyIcon ? 10 : 0)}px;
    fill: ${(props) => props.theme.colors.baseGrey2};
  }

  ${(props) => props.active && active}
  ${(props) => props.primary && primary}
  ${(props) =>
    props.mode === "tiny" && tiny}
  ${(props) =>
    props.mode === "button" && buttonStyle}
  ${(props) =>
    props.fullWidth &&
    css`
      flex-grow: 1;
    `}
`;

const active = css`
  border: 1px solid ${(props) => props.theme.colors.baseWhite};
  .icon {
    ${(props) => isGradient(props)}
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.baseWhite};
  }
`;

const buttonStyle = css<{ active?: boolean }>`
  background-color: ${(props) => props.theme.colors.baseGrey4};
  ${(props) =>
    !props.active &&
    css`
      &:not(:hover) {
        path.colored {
          fill: #6c7593;
        }
      }
    `}

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.baseGrey3};
  }
  &:active {
    border: 1px solid ${(props) => props.theme.colors.baseWhite};
  }
`;

const primary = css`
  background: ${(props) => props.theme.gradients.brandGradient};
  padding: 10px 20px;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.baseWhite};
  }
  &:active {
    background: ${(props) => props.theme.colors.baseWhite};
    span {
      background: ${(props) => props.theme.gradients.brandGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const tiny = css<{ active?: boolean }>`
  border: none;
  height: 30px;
  width: 30px;
  padding: 0;
  border: 0 !important;
  .icon {
    fill: ${(props) => props.theme.colors.baseGrey2};
  }

  ${(props) =>
    props.active &&
    css`
      .icon,
      &:hover {
        fill: ${(props) => props.theme.colors.baseWhite} !important;
      }
    `}

  &:hover {
    .icon {
      fill: ${(props) =>
        props.active
          ? props.theme.colors.baseWhite
          : props.theme.colors.baseGrey1} !important;
    }
  }

  &:active {
    .icon {
      fill: ${(props) => props.theme.colors.baseWhite} !important;
    }
  }
`;
