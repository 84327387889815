import React, { useMemo } from "react";
import { getSvgPoints } from "../../../helpers/springVisualizer";
import { SpringGrid } from "./SpringGrid";
import { styled } from "../../UI/helpers/theme";

type Props = {
  withGrid: boolean;
  curveColor: string;
  curveWidth: number;
  value: {
    tension: number;
    friction: number;
    velocity: number;
  };
};

export const SpringCurve = ({
  value,
  curveColor,
  curveWidth,
  withGrid,
}: Props) => {
  const points = useMemo(() => getSvgPoints(value).flat().join(","), [value]);

  return (
    <Wrapper>
      <svg width="300" height="300">
        <polyline
          id="polyline"
          fill="none"
          stroke={curveColor}
          strokeWidth={curveWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
          points={points}
        />
      </svg>
      {withGrid && <SpringGrid className="grid" />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  user-select: none;
  border: 1px solid #292e3d;
  overflow: visible;
  margin-bottom: 20px;
  height: 300px;

  .grid {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;
