import React, { useCallback, useState } from "react";
import { Copy, h3 } from "../../UI/Copy";
import { styled } from "../../UI/helpers/theme";
import { clearArray } from "../../../helpers/common";

type Props = {
  curve: number[];
  onChange: (number: []) => void;
};

const CurveInputComponent = ({
  initValue,
  onSetValue,
  onBlur,
  ...props
}: any) => {
  const [value, setValue] = useState<string>(initValue);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (
        /^(0|1|-0|-1)(.|)([0-9]+|),( |)((-|)[0-9]+)+(.|)([0-9]+|),( |)(0|1|-0|-1)(.|)([0-9]+|),( |)((-|)[0-9]+)+(.|)([0-9]+|)(,|)( |)$/.test(
          value
        )
      ) {
        const parsedCurve = value
          .split(",")
          .map((i: string) => parseFloat(i?.trim()));
        const filtered = parsedCurve.filter((i, index) => {
          if (clearArray(i)) {
            if ([0, 2].includes(index) && i > 1) {
              return false;
            }
            return true;
          }
          return false;
        });
        if (filtered.length === 4) {
          onSetValue(filtered);
        }
        onBlur();
      } else {
        onBlur();
      }
    },
    [value, onSetValue, onBlur]
  );

  return (
    <form onSubmit={onSubmit}>
      <StyledInput
        value={value}
        onChange={(e) => setValue(e.target.value.replace(/[^0-9, .-]/g, ""))}
        onBlur={onSubmit}
        onFocus={(e) => {
          e.target.select();
        }}
        {...props}
      />
    </form>
  );
};

export const CurveInput = ({ curve, onChange }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [x1, y1, x2, y2] = curve;
  const formattedValue = curve
    .map((i) =>
      Number(i).toLocaleString("fullwide", {
        maximumFractionDigits: 3,
        minimumFractionDigits: 1,
      })
    )
    .join(", ");
  const onStartEdit = () => setEditMode(true);
  const onStopEdit = () => setEditMode(false);

  return (
    <Wrapper onClick={onStartEdit}>
      {editMode && (
        <CurveInputComponent
          autoFocus
          onBlur={onStopEdit}
          initValue={formattedValue}
          onSetValue={onChange}
        />
      )}
      <Copy display="h3" color="brandPink" className="curve-number" nowrap>
        {Number(x1).toLocaleString("fullwide", {
          maximumFractionDigits: 3,
          minimumFractionDigits: 1,
        })}
        <Copy display="h3" color="baseGrey3">
          ,
        </Copy>
      </Copy>
      <Copy display="h3" color="brandPink" className="curve-number" nowrap>
        {Number(y1).toLocaleString("fullwide", {
          maximumFractionDigits: 3,
          minimumFractionDigits: 1,
        })}
        <Copy display="h3" color="baseGrey3">
          ,
        </Copy>
      </Copy>
      <Copy display="h3" color="brandBlue" className="curve-number" nowrap>
        {Number(x2).toLocaleString("fullwide", {
          maximumFractionDigits: 3,
          minimumFractionDigits: 1,
        })}
        <Copy display="h3" color="baseGrey3">
          ,
        </Copy>
      </Copy>
      <Copy display="h3" color="brandBlue" className="curve-number" nowrap>
        {Number(y2).toLocaleString("fullwide", {
          maximumFractionDigits: 3,
          minimumFractionDigits: 1,
        })}
      </Copy>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 26px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.baseGrey3};
  cursor: pointer;

  .curve-number:not(:last-child) {
    margin-right: 4px;
  }
`;

const StyledInput = styled.input`
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: black;
  border: none;
  border-radius: 5px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors.baseGrey2};
  color: ${(props) => props.theme.colors.baseWhite};
  appearance: none;

  &:focus,
  &:hover {
    outline: none;
  }

  ${h3}
`;
