export enum AlignTypes {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum LoopTypes {
  repeatOnce = "repeat-once",
  repeatOnce2 = "repeat-once-2",
  infinity = "infinity",
}
