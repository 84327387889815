import { $url } from "./store";
import { stringify, parse } from "querystring";

import {
  setCurve,
  setKind,
  setBenzier,
  setStyle,
  setAnimation,
  setLoop,
  setAlign,
  setMs,
  setBeginnerMode,
  setAdvancedMode,
  setCodeView,
  setButtonView,
  setLanguage,
} from "./events";
import { itemPayload } from "./init";
import { LoopTypes, AlignTypes } from "./contants";

$url
  .on(setBeginnerMode, (state) => ({ ...state, mode: "beginner" }))
  .on(setAdvancedMode, (state) => ({ ...state, mode: "advanced" }))
  .on(setCurve, (state, payload) => ({
    ...state,
    curve: payload.map((i) => i.toFixed(3)).join(","),
  }))
  .on(setKind, (state, payload) => ({
    ...state,
    kind: payload,
    benzier: payload === "spring" ? "in" : state.benzier,
    ...itemPayload(payload, "default"),
  }))
  .on(setBenzier, (state, payload) => ({
    ...state,
    benzier: payload,
  }))
  .on(setStyle, (state, payload) => ({
    ...state,
    style: payload,
  }))
  .on(setAnimation, (state, payload) => ({ ...state, animation: payload }))
  // @ts-ignore
  .on(setLoop, (state, payload) => ({ ...state, loop: LoopTypes[payload] }))
  // @ts-ignore
  .on(setAlign, (state, payload) => ({ ...state, align: AlignTypes[payload] }))
  .on(setMs, (state, payload) => ({ ...state, ms: +payload }))
  .on(setLanguage, (state, language) => ({ ...state, language }))
  .on(setButtonView, (state) => ({ ...state, exportView: "buttons" }))
  .on(setCodeView, (state) => ({ ...state, exportView: "code" }));

$url.watch((state, payload) => {
  const locationQuery = parse(window.location.search.substr(1));

  // @ts-ignore
  window.history.pushState(
    "",
    "",
    `?${stringify({ ...locationQuery, ...state })}`
  );
});

export const matchUrlState = (query: any) => {
  const isAdvancedMode = query.mode === "advanced";
  if (query.mode) {
    if (isAdvancedMode) setAdvancedMode();
    if (!isAdvancedMode) setBeginnerMode();
  }

  // Recipe
  if (query.kind) {
    setKind(query.kind);
  }
  if (query.benzier) {
    setBenzier(query.benzier || "in");
  }
  if (query.style) {
    setStyle(query.style);
  }

  // Preview
  if (query.animation) {
    setAnimation(query.animation);
  }

  if (query.loop) {
    const loop: "repeatOnce" | "infinity" = query.loop as any;
    setLoop(LoopTypes[loop] || LoopTypes.repeatOnce);
  }

  if (query.align && isAdvancedMode) {
    const align: "horizontal" | "vertical" = query.align as any;
    setAlign(AlignTypes[align] || AlignTypes.vertical);
  }

  if (query.ms) {
    setMs(parseInt(query.ms, 10));
  }

  if (query.curve && !Array.isArray(query.curve)) {
    setCurve(query.curve.split(",").map(parseFloat));
  }

  if (query.language) {
    setLanguage(query.language);
  }

  if (query.code && isAdvancedMode) {
    setCodeView();
    setLoop(LoopTypes.infinity);
  } else if (query.code) {
    setButtonView();
  }
};
