import React from "react";
import { styled } from "../../UI/helpers/theme";
import { Copy } from "../../UI/Copy";

type Props = {
  addStyle?: any;
  curve: number[];
  grey?: boolean;
  loop?: boolean;
  ms: number;
  animation?: any;
};

const LetterComponent = ({ letter }: { letter: string }) => (
  <Letter display="h2" color="baseWhite">
    {letter}
  </Letter>
);

export const CubeElement = (props: Props) => {
  return (
    <Cube {...props}>
      <div className="cube">
        <div className="side front">
          <LetterComponent letter="A" />
        </div>
        <div className="side back">
          <LetterComponent letter="A" />
        </div>
        <div className="side right">
          <LetterComponent letter="B" />
        </div>
        <div className="side left">
          <LetterComponent letter="B" />
        </div>
        <div className="side top">
          <LetterComponent letter="B" />
        </div>
        <div className="side bottom">
          <LetterComponent letter="B" />
        </div>
      </div>
    </Cube>
  );
};

const Letter = styled(Copy)`
  font-size: 100%;
  color: white;
  font-weight: bold;
  user-select: none;
`;

export const RectElement = styled.div<{
  addStyle?: any;
  curve: number[];
  grey?: boolean;
  loop?: boolean;
  ms: number;
  animation?: any;
  animationName: string;
}>`
  width: 50px;
  height: 50px;
  background-color: ${(props) =>
    props.grey ? props.theme.colors.baseGrey1 : props.theme.colors.brandBlue};
  animation: ${(props) => props.animation} ${(props) => props.ms || 0}ms
    ${(props) =>
      props.curve.length >= 4
        ? `cubic-bezier(${props.curve.join(",")})`
        : `linear`}
    ${(props) => (props.loop ? "infinite" : "forwards")};
  animation-direction: ${(props) =>
    props.animationName === "rotate" ? "inherit" : "alternate"};
  flex: 0 0 50px;
  will-change: transform;

  ${(props) => props.addStyle}
`;
// border: 2px solid rgba(255, 255, 255, 0.2);

const Cube = styled.div<Props>`
  position: absolute;
  width: 75px;
  height: 75px;
  perspective: 200px;
  ${(props) => props.addStyle}

  .cube {
    width: inherit;
    height: inherit;
    transform-style: preserve-3d;

    background-color: ${(props) =>
      props.grey ? props.theme.colors.baseGrey1 : props.theme.colors.brandBlue};
    animation: ${(props) => props.animation} ${(props) => props.ms || 0}ms
      ${(props) =>
        props.curve.length === 4
          ? `cubic-bezier(${props.curve.join(",")})`
          : `linear`}
      ${(props) => (props.loop ? "infinite" : "forwards")};
    will-change: transform;
  }

  .side {
    position: absolute;
    width: inherit;
    height: inherit;
    font: normal 70px Arial;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .front {
    transform: translateZ(37.5px);
  }

  .back {
    transform: rotateY(180deg) translateZ(37.5px);
  }

  .right {
    transform: rotateY(90deg) translateZ(37.5px);
  }

  .left {
    transform: rotateY(-90deg) translateZ(37.5px);
  }

  .top {
    transform: rotateX(90deg) translateZ(37.5px);
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(37.5px);
  }

  /* Blue */
  .front,
  .back {
    border: 2px solid
      ${(props) =>
        props.grey
          ? props.theme.colors.baseGrey1
          : props.theme.colors.brandBlueLighter};
    background-color: ${(props) =>
      props.grey ? props.theme.colors.baseGrey2 : props.theme.colors.brandBlue};
  }

  /* Pink */
  .right,
  .left,
  .top,
  .bottom {
    border: 2px solid
      ${(props) => (props.grey ? "#B4C0E5" : props.theme.colors.brandPink)};
    background-color: ${(props) =>
      props.grey
        ? props.theme.colors.baseGrey1
        : props.theme.colors.brandPinkDarker};
  }
`;
