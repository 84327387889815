import React, { useState, useCallback, useEffect } from "react";
import { NumberInput } from "../../UI/NumberInput";
import { styled } from "../../UI/helpers/theme";
import { LoopTypes } from "../../../store/contants";
import { Button } from "../../UI/Button";

type Props = {
  disabled: boolean;
  ms: number;
  loop: LoopTypes;
  onSetLoop: (value: LoopTypes) => void;
  onSetMS: (value: number) => void;
  withLoopControl: boolean;
};

export const SpeedControl = ({
  disabled,
  loop,
  ms,
  onSetLoop,
  onSetMS,
  withLoopControl,
}: Props) => {
  const [speed, setSpeed] = useState(ms);
  const handler = useCallback((value) => setSpeed(value), [setSpeed]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (speed !== 1500) onSetMS(speed);
    }, 700);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speed]);
  useEffect(() => {
    setSpeed(ms);
  }, [ms]);

  const isRepeatOnce = [LoopTypes.repeatOnce, LoopTypes.repeatOnce2].includes(
    loop
  );
  const onRepeat = useCallback(() => {
    if (!isRepeatOnce || loop === LoopTypes.repeatOnce2)
      onSetLoop(LoopTypes.repeatOnce);
    if (loop === LoopTypes.repeatOnce) onSetLoop(LoopTypes.repeatOnce2);
  }, [isRepeatOnce, loop, onSetLoop]);

  return (
    <Wrapper>
      <StyledNumberInput
        value={speed}
        max={5000}
        min={0}
        step={50}
        onChange={handler}
        suffix="ms"
        disabled={disabled}
      />
      {withLoopControl && (
        <ButtonsWrapper>
          <Button
            mode="tiny"
            active={isRepeatOnce}
            icon="repeatOnce"
            onClick={onRepeat}
            disabled={disabled}
          />
          <Button
            mode="tiny"
            active={!isRepeatOnce}
            icon="infinity"
            onClick={() => onSetLoop(LoopTypes.infinity)}
            disabled={disabled}
          />
        </ButtonsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 80px;

  ${(props) => props.theme.screen.tablet} {
    flex-direction: row-reverse;
    width: initial;
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 80px;

  ${(props) => props.theme.screen.tablet} {
    display: flex;
    flex-direction: row;
    margin-top: 0;
  }
`;

const StyledNumberInput = styled(NumberInput)`
  ${(props) => props.theme.screen.tablet} {
    margin-left: auto;
    width: initial;
  }
`;
