import { LoopTypes } from "../store/contants";

export const repeatOnce = (loop: any): LoopTypes => {
  const isRepeatOnce = [LoopTypes.repeatOnce, LoopTypes.repeatOnce2].includes(
    loop
  );
  if (isRepeatOnce) {
    if (loop === LoopTypes.repeatOnce2) {
      return LoopTypes.repeatOnce;
    }
    if (loop === LoopTypes.repeatOnce) {
      return LoopTypes.repeatOnce2;
    }
  }

  return loop;
};
