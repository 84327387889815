import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { theme } from "./components/UI/helpers/theme";
import { GlobalTheme } from "./components/UI/GlobalTheme";
import { ROUTES } from "./routes";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          {ROUTES.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
        <GlobalTheme />
      </BrowserRouter>
    </ThemeProvider>
  );
};
