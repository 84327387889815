import React from "react";
import BezierComponent from "./BezierComponent";

export default class Handle extends BezierComponent {
  shouldComponentUpdate(nextProps) {
    if (super.shouldComponentUpdate(nextProps)) return true;
    const {
      index,
      handleRadius,
      handleColor,
      hover,
      down,
      background,
      handleStroke,
      xval,
      yval,
      onPointerEnter,
      onPointerLeave,
      onPointerDown,
    } = this.props;
    return (
      nextProps.index !== index ||
      nextProps.handleRadius !== handleRadius ||
      nextProps.handleColor !== handleColor ||
      nextProps.hover !== hover ||
      nextProps.down !== down ||
      nextProps.background !== background ||
      nextProps.handleStroke !== handleStroke ||
      nextProps.xval !== xval ||
      nextProps.yval !== yval ||
      nextProps.onPointerDown !== onPointerDown ||
      nextProps.onPointerLeave !== onPointerLeave ||
      nextProps.onPointerEnter !== onPointerEnter
    );
  }

  render() {
    const { x, y } = this;
    const {
      index,
      handleRadius,
      handleColor,
      hover,
      down,
      // background,
      handleStroke,
      xval,
      yval,
      onPointerEnter,
      onPointerLeave,
      onPointerDown,
    } = this.props;

    const sx = x(index);
    const sy = y(index);
    const cx = x(xval);
    const cy = y(yval);
    const a = Math.atan2(cy - sy, cx - sx);
    const cxs = cx - handleRadius * Math.cos(a);
    const cys = cy - handleRadius * Math.sin(a);

    return (
      <g>
        <line
          stroke={handleColor}
          strokeWidth={hover || down ? handleStroke : handleStroke}
          x1={cxs}
          y1={cys}
          x2={sx}
          y2={sy}
        />
        <circle
          cx={cx}
          cy={cy}
          r={handleRadius}
          fill="white"
          stroke={handleColor}
          strokeWidth={down ? 2.2 * handleStroke : 2 * handleStroke}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerLeave}
        />
      </g>
    );
  }
}
