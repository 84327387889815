import React from "react";
import { theme } from "./helpers/theme";

import { ReactComponent as simple } from "../../assets/svg/icon-simple.svg";
import { ReactComponent as advanced } from "../../assets/svg/icon-advanced.svg";
import { ReactComponent as check } from "../../assets/svg/icon-check.svg";
import { ReactComponent as x } from "../../assets/svg/icon-x.svg";
import { ReactComponent as alignV } from "../../assets/svg/icon-align-v.svg";
import { ReactComponent as alignH } from "../../assets/svg/icon-align-h.svg";
import { ReactComponent as repeatOnce } from "../../assets/svg/icon-repeat-once.svg";
import { ReactComponent as infinity } from "../../assets/svg/infinity.svg";
import { ReactComponent as document } from "../../assets/svg/icon-document.svg";
import { ReactComponent as copy } from "../../assets/svg/icon-copy.svg";
import { ReactComponent as code } from "../../assets/svg/icon-code.svg";
import { ReactComponent as time } from "../../assets/svg/icon-time.svg";
import { ReactComponent as move } from "../../assets/svg/icon-move.svg";
import { ReactComponent as width } from "../../assets/svg/icon-width.svg";
import { ReactComponent as height } from "../../assets/svg/icon-height.svg";
import { ReactComponent as scale } from "../../assets/svg/icon-scale.svg";
import { ReactComponent as rotate } from "../../assets/svg/icon-rotate.svg";
import { ReactComponent as opacity } from "../../assets/svg/icon-opacity.svg";
import { ReactComponent as threed } from "../../assets/svg/icon-3d.svg";
import { ReactComponent as frames } from "../../assets/svg/icon-frames.svg";
import { ReactComponent as emojiCool } from "../../assets/svg/icon-emoji-cool.svg";
import { ReactComponent as emojiKiss } from "../../assets/svg/icon-emoji-kiss.svg";
import { ReactComponent as emojiNerd } from "../../assets/svg/icon-emoji-nerd.svg";
import { ReactComponent as emojiYum } from "../../assets/svg/icon-emoji-yum.svg";
import { ReactComponent as emojiEarth } from "../../assets/svg/icon-emoji-earth.svg";
import { ReactComponent as facebook } from "../../assets/svg/icon-facebook.svg";
import { ReactComponent as twitter } from "../../assets/svg/icon-twitter.svg";
import { ReactComponent as instagram } from "../../assets/svg/icon-instagram.svg";
import { ReactComponent as dribbble } from "../../assets/svg/icon-dribbble.svg";
import { ReactComponent as youtube } from "../../assets/svg/icon-youtube.svg";
import { ReactComponent as domain } from "../../assets/svg/icon-domain.svg";

import { ReactComponent as cssActive } from "../../assets/svg/icon-css3-active.svg";
import { ReactComponent as scssActive } from "../../assets/svg/icon-scss-active.svg";
import { ReactComponent as aeActive } from "../../assets/svg/icon-ae-active.svg";
import { ReactComponent as appleActive } from "../../assets/svg/icon-apple-active.svg";
import { ReactComponent as jsActive } from "../../assets/svg/icon-js-active.svg";
import { ReactComponent as swiftActive } from "../../assets/svg/icon-swift-active.svg";

import { ReactComponent as css } from "../../assets/svg/icon-css3.svg";
import { ReactComponent as scss } from "../../assets/svg/icon-scss.svg";
import { ReactComponent as ae } from "../../assets/svg/icon-ae.svg";
import { ReactComponent as apple } from "../../assets/svg/icon-apple.svg";
import { ReactComponent as js } from "../../assets/svg/icon-js.svg";
import { ReactComponent as swift } from "../../assets/svg/icon-swift.svg";

// curves
import { ReactComponent as spring } from "../../assets/svg/samples/spring.svg";
import { ReactComponent as bounce } from "../../assets/svg/samples/bounce.svg";
import { ReactComponent as ease_in } from "../../assets/svg/samples/in.svg";
import { ReactComponent as ease_out } from "../../assets/svg/samples/out.svg";
import { ReactComponent as ease_inout } from "../../assets/svg/samples/in-out.svg";
import { ReactComponent as ease_outin } from "../../assets/svg/samples/out-in.svg";
import { ReactComponent as ease_in_back } from "../../assets/svg/samples/InBack.svg";
import { ReactComponent as ease_in_circ } from "../../assets/svg/samples/InCirc.svg";
import { ReactComponent as ease_in_cubic } from "../../assets/svg/samples/InCubic.svg";
import { ReactComponent as ease_in_elastic } from "../../assets/svg/samples/InElastic.svg";
import { ReactComponent as ease_in_epic } from "../../assets/svg/samples/InEpic.svg";
import { ReactComponent as ease_in_expo } from "../../assets/svg/samples/InExpo.svg";
import { ReactComponent as ease_inout_back } from "../../assets/svg/samples/InOutBack.svg";
import { ReactComponent as ease_inout_circ } from "../../assets/svg/samples/InOutCirc.svg";
import { ReactComponent as ease_inout_cubic } from "../../assets/svg/samples/InOutCubic.svg";
import { ReactComponent as ease_inout_elastic } from "../../assets/svg/samples/InOutElastic.svg";
import { ReactComponent as ease_inout_epic } from "../../assets/svg/samples/InOutEpic.svg";
import { ReactComponent as ease_inout_expo } from "../../assets/svg/samples/InOutExpo.svg";
import { ReactComponent as ease_inout_quad } from "../../assets/svg/samples/InOutQuad.svg";
import { ReactComponent as ease_inout_quart } from "../../assets/svg/samples/InOutQuart.svg";
import { ReactComponent as ease_inout_quint } from "../../assets/svg/samples/InOutQuint.svg";
import { ReactComponent as ease_inout_sine } from "../../assets/svg/samples/InOutSine.svg";
import { ReactComponent as ease_in_quad } from "../../assets/svg/samples/InQuad.svg";
import { ReactComponent as ease_in_quart } from "../../assets/svg/samples/InQuart.svg";
import { ReactComponent as ease_in_quint } from "../../assets/svg/samples/InQuint.svg";
import { ReactComponent as ease_in_sine } from "../../assets/svg/samples/InSine.svg";
import { ReactComponent as linear } from "../../assets/svg/samples/linear.svg";
import { ReactComponent as ease_out_back } from "../../assets/svg/samples/OutBack.svg";
import { ReactComponent as ease_out_circ } from "../../assets/svg/samples/OutCirc.svg";
import { ReactComponent as ease_out_cubic } from "../../assets/svg/samples/OutCubic.svg";
import { ReactComponent as ease_out_elastic } from "../../assets/svg/samples/OutElastic.svg";
import { ReactComponent as ease_out_epic } from "../../assets/svg/samples/OutEpic.svg";
import { ReactComponent as ease_out_expo } from "../../assets/svg/samples/OutExpo.svg";
import { ReactComponent as ease_outin_back } from "../../assets/svg/samples/OutInBack.svg";
import { ReactComponent as bounce_in } from "../../assets/svg/samples/InBounce.svg";
import { ReactComponent as bounce_inout } from "../../assets/svg/samples/InOutBounce.svg";
import { ReactComponent as bounce_out } from "../../assets/svg/samples/OutBounce.svg";
import { ReactComponent as bounce_outin } from "../../assets/svg/samples/OutInBounce.svg";
import { ReactComponent as ease_outin_circ } from "../../assets/svg/samples/OutInCirc.svg";
import { ReactComponent as ease_outin_cubic } from "../../assets/svg/samples/OutInCubic.svg";
import { ReactComponent as ease_outin_elastic } from "../../assets/svg/samples/OutInElastic.svg";
import { ReactComponent as ease_outin_epic } from "../../assets/svg/samples/OutInEpic.svg";
import { ReactComponent as ease_outin_expo } from "../../assets/svg/samples/OutInExpo.svg";
import { ReactComponent as ease_outin_quad } from "../../assets/svg/samples/OutInQuad.svg";
import { ReactComponent as ease_outin_quart } from "../../assets/svg/samples/OutInQuart.svg";
import { ReactComponent as ease_outin_quint } from "../../assets/svg/samples/OutInQuint.svg";
import { ReactComponent as ease_outin_sine } from "../../assets/svg/samples/OutInSine.svg";
import { ReactComponent as ease_out_quad } from "../../assets/svg/samples/OutQuad.svg";
import { ReactComponent as ease_out_quart } from "../../assets/svg/samples/OutQuart.svg";
import { ReactComponent as ease_out_quint } from "../../assets/svg/samples/OutQuint.svg";
import { ReactComponent as ease_out_sine } from "../../assets/svg/samples/OutSine.svg";

// spring
import { ReactComponent as spring_in_alert } from "../../assets/svg/samples/springAlert.svg";
import { ReactComponent as spring_in_easy } from "../../assets/svg/samples/springEasy.svg";
import { ReactComponent as spring_in_floppy } from "../../assets/svg/samples/springFloppy.svg";
import { ReactComponent as spring_in_gentle } from "../../assets/svg/samples/springGentle.svg";
import { ReactComponent as spring_in_loose } from "../../assets/svg/samples/springLoose.svg";
import { ReactComponent as spring_in_relaxed } from "../../assets/svg/samples/springRelaxed.svg";
import { ReactComponent as spring_in_sling } from "../../assets/svg/samples/springSling.svg";
import { ReactComponent as spring_in_slow } from "../../assets/svg/samples/springSlow.svg";
import { ReactComponent as spring_in_snap } from "../../assets/svg/samples/springSnap.svg";
import { ReactComponent as spring_in_stiff } from "../../assets/svg/samples/springStiff.svg";
import { ReactComponent as spring_in_swift } from "../../assets/svg/samples/springSwift.svg";

// bounce
import { ReactComponent as bounce_out_alert } from "../../assets/svg/samples/bounceInAlert.svg";
import { ReactComponent as bounce_out_easy } from "../../assets/svg/samples/bounceInEasy.svg";
import { ReactComponent as bounce_out_floppy } from "../../assets/svg/samples/bounceInFloppy.svg";
import { ReactComponent as bounce_out_gentle } from "../../assets/svg/samples/bounceInGentle.svg";
import { ReactComponent as bounce_out_loose } from "../../assets/svg/samples/bounceInLoose.svg";
import { ReactComponent as bounce_inout_alert } from "../../assets/svg/samples/bounceInOutAlert.svg";
import { ReactComponent as bounce_inout_easy } from "../../assets/svg/samples/bounceInOutEasy.svg";
import { ReactComponent as bounce_inout_floppy } from "../../assets/svg/samples/bounceInOutFloppy.svg";
import { ReactComponent as bounce_inout_gentle } from "../../assets/svg/samples/bounceInOutGentle.svg";
import { ReactComponent as bounce_inout_loose } from "../../assets/svg/samples/bounceInOutLoose.svg";
import { ReactComponent as bounce_inout_relaxed } from "../../assets/svg/samples/bounceInOutRelaxed.svg";
import { ReactComponent as bounce_inout_sling } from "../../assets/svg/samples/bounceInOutSling.svg";
import { ReactComponent as bounce_inout_slow } from "../../assets/svg/samples/bounceInOutSlow.svg";
import { ReactComponent as bounce_inout_snap } from "../../assets/svg/samples/bounceInOutSnap.svg";
import { ReactComponent as bounce_inout_stiff } from "../../assets/svg/samples/bounceInOutStiff.svg";
import { ReactComponent as bounce_inout_swift } from "../../assets/svg/samples/bounceInOutSwift.svg";
import { ReactComponent as bounce_out_relaxed } from "../../assets/svg/samples/bounceInRelaxed.svg";
import { ReactComponent as bounce_out_sling } from "../../assets/svg/samples/bounceInSling.svg";
import { ReactComponent as bounce_out_slow } from "../../assets/svg/samples/bounceInSlow.svg";
import { ReactComponent as bounce_out_snap } from "../../assets/svg/samples/bounceInSnap.svg";
import { ReactComponent as bounce_out_stiff } from "../../assets/svg/samples/bounceInStiff.svg";
import { ReactComponent as bounce_out_swift } from "../../assets/svg/samples/bounceInSwift.svg";
import { ReactComponent as bounce_in_alert } from "../../assets/svg/samples/bounceOutAlert.svg";
import { ReactComponent as bounce_in_easy } from "../../assets/svg/samples/bounceOutEasy.svg";
import { ReactComponent as bounce_in_floppy } from "../../assets/svg/samples/bounceOutFloppy.svg";
import { ReactComponent as bounce_in_gentle } from "../../assets/svg/samples/bounceOutGentle.svg";
import { ReactComponent as bounce_outin_alert } from "../../assets/svg/samples/bounceOutInAlert.svg";
import { ReactComponent as bounce_outin_easy } from "../../assets/svg/samples/bounceOutInEasy.svg";
import { ReactComponent as bounce_outin_floppy } from "../../assets/svg/samples/bounceOutInFloppy.svg";
import { ReactComponent as bounce_outin_gentle } from "../../assets/svg/samples/bounceOutInGentle.svg";
import { ReactComponent as bounce_outin_loose } from "../../assets/svg/samples/bounceOutInLoose.svg";
import { ReactComponent as bounce_outin_relaxed } from "../../assets/svg/samples/bounceOutInRelaxed.svg";
import { ReactComponent as bounce_outin_sling } from "../../assets/svg/samples/bounceOutInSling.svg";
import { ReactComponent as bounce_outin_slow } from "../../assets/svg/samples/bounceOutInSlow.svg";
import { ReactComponent as bounce_outin_snap } from "../../assets/svg/samples/bounceOutInSnap.svg";
import { ReactComponent as bounce_outin_stiff } from "../../assets/svg/samples/bounceOutInStiff.svg";
import { ReactComponent as bounce_outin_swift } from "../../assets/svg/samples/bounceOutInSwift.svg";
import { ReactComponent as bounce_in_loose } from "../../assets/svg/samples/bounceOutLoose.svg";
import { ReactComponent as bounce_in_relaxed } from "../../assets/svg/samples/bounceOutRelaxed.svg";
import { ReactComponent as bounce_in_sling } from "../../assets/svg/samples/bounceOutSling.svg";
import { ReactComponent as bounce_in_slow } from "../../assets/svg/samples/bounceOutSlow.svg";
import { ReactComponent as bounce_in_snap } from "../../assets/svg/samples/bounceOutSnap.svg";
import { ReactComponent as bounce_in_stiff } from "../../assets/svg/samples/bounceOutStiff.svg";
import { ReactComponent as bounce_in_swift } from "../../assets/svg/samples/bounceOutSwift.svg";

export const ICONS: { [key: string]: any } = {
  // system
  simple,
  advanced,
  check,
  x,
  alignV,
  alignH,
  repeatOnce,
  infinity,
  document,
  copy,
  code,
  time,
  move,
  width,
  height,
  scale,
  rotate,
  opacity,
  threed,
  frames,
  emojiCool,
  emojiKiss,
  emojiNerd,
  emojiYum,
  emojiEarth,
  facebook,
  twitter,
  instagram,
  dribbble,
  youtube,
  domain,
  // language
  cssActive,
  scssActive,
  aeActive,
  appleActive,
  jsActive,
  swiftActive,
  css,
  scss,
  ae,
  apple,
  js,
  swift,
  // easing
  spring,
  bounce,
  ease_in_back,
  ease_in_circ,
  ease_in_cubic,
  ease_in_elastic,
  ease_in_epic,
  ease_in_expo,
  ease_inout_back,
  ease_inout_circ,
  ease_inout_cubic,
  ease_inout_elastic,
  ease_inout_epic,
  ease_inout_expo,
  ease_inout_quad,
  ease_inout_quart,
  ease_inout_quint,
  ease_inout_sine,
  ease_in_quad,
  ease_in_quart,
  ease_in_quint,
  ease_in_sine,
  ease_out_back,
  ease_out_circ,
  ease_out_cubic,
  ease_out_elastic,
  ease_out_epic,
  ease_out_expo,
  ease_outin_back,
  ease_outin_circ,
  ease_outin_cubic,
  ease_outin_elastic,
  ease_outin_epic,
  ease_outin_expo,
  ease_outin_quad,
  ease_outin_quart,
  ease_outin_quint,
  ease_outin_sine,
  ease_out_quad,
  ease_out_quart,
  ease_out_quint,
  ease_out_sine,
  linear,
  ease_in,
  ease_out,
  ease_inout,
  ease_outin,
  spring_in_alert,
  spring_in_easy,
  spring_in_floppy,
  spring_in_gentle,
  spring_in_loose,
  spring_in_relaxed,
  spring_in_sling,
  spring_in_slow,
  spring_in_snap,
  spring_in_stiff,
  spring_in_swift,
  bounce_in,
  bounce_inout,
  bounce_out,
  bounce_outin,

  //bounce
  bounce_out_alert,
  bounce_out_easy,
  bounce_out_floppy,
  bounce_out_gentle,
  bounce_out_loose,
  bounce_inout_alert,
  bounce_inout_easy,
  bounce_inout_floppy,
  bounce_inout_gentle,
  bounce_inout_loose,
  bounce_inout_relaxed,
  bounce_inout_sling,
  bounce_inout_slow,
  bounce_inout_snap,
  bounce_inout_stiff,
  bounce_inout_swift,
  bounce_out_relaxed,
  bounce_out_sling,
  bounce_out_slow,
  bounce_out_snap,
  bounce_out_stiff,
  bounce_out_swift,
  bounce_in_alert,
  bounce_in_easy,
  bounce_in_floppy,
  bounce_in_gentle,
  bounce_outin_alert,
  bounce_outin_easy,
  bounce_outin_floppy,
  bounce_outin_gentle,
  bounce_outin_loose,
  bounce_outin_relaxed,
  bounce_outin_sling,
  bounce_outin_slow,
  bounce_outin_snap,
  bounce_outin_stiff,
  bounce_outin_swift,
  bounce_in_loose,
  bounce_in_relaxed,
  bounce_in_sling,
  bounce_in_slow,
  bounce_in_snap,
  bounce_in_stiff,
  bounce_in_swift,
};

export type IconNames = keyof typeof ICONS;
export type IconColors = keyof typeof theme.colors | "url(#gradient)";

type Props = {
  name: IconNames | string;
  size?: number;
  color?: IconColors;
};

export const Icon = ({ name, size = 20, color }: Props) => {
  const IconComponent = name in ICONS ? ICONS[name] : () => null;

  return (
    <IconComponent
      width={size}
      height={size}
      className="icon"
      // @ts-ignore
      fill={color in theme.colors ? theme.colors[color] : color}
    />
  );
};
