import React, { useEffect } from "react";
import { SpringCurve } from "./SpringCurve";
import { SpringInput } from "./SpringInput";
import { styled } from "../../UI/helpers/theme";

type Props = {
  value: number[];
  onChange: (value: number[]) => void;
  style: string;
  advancedMode: boolean;
};

export const SpringEditor = ({
  value,
  onChange,
  style,
  advancedMode,
}: Props) => {
  const [tension, friction, velocity] = value;

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Wrapper>
      <SpringCurve
        value={{
          tension,
          friction,
          velocity,
        }}
        curveColor="url(#gradient)"
        curveWidth={5}
        withGrid={advancedMode}
      />
      <fieldset tabIndex={0}>
        <SpringInput
          max={500}
          min={0}
          step={1}
          label="Tension"
          value={tension}
          onChange={(t) => onChange([t, value[1], value[2]])}
          tabIndex={10}
        />
        <SpringInput
          max={180}
          min={0}
          step={1}
          label="Friction"
          value={friction}
          onChange={(t) => onChange([value[0], t, value[2]])}
          tabIndex={11}
        />
        <SpringInput
          max={50}
          min={-50}
          step={1}
          label="Velocity"
          value={velocity}
          onChange={(t) => onChange([value[0], value[1], t])}
          tabIndex={12}
        />
      </fieldset>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 100px;
`;
