import { Complex } from "../libs/keyframegen";
import get from "lodash/get";

export function reverse(t: number, f: Function) {
  return function (t: number) {
    return 1 - f(1 - t);
  };
}

export function reflect(f: Function) {
  return function (t: number) {
    return 0.5 * (t < 0.5 ? f(2 * t) : 2 - f(2 - 2 * t));
  };
}

const fns = {
  in: bounceIn,
  inout: bounceInOut,
  outin: bounceOutIn,
};

export function bounceIn(t: number, f: Function) {
  return 1 - f(1 - t);
}

export function bounceOutIn(t: number, f: Function) {
  return reflect(f)(t);
}

export function bounceInOut(t: number, f: Function) {
  return ((t *= 2) <= 1 ? 1 - f(1 - t) : f(t - 1) + 1) / 2;
}

export function generateCurvePoints({
  bounces,
  stiffness,
  benzier,
}: {
  bounces: number;
  stiffness: number;
  benzier: string;
}) {
  const elements: any[] = [];
  const complex = new Complex();
  const generatedBounces: any = complex
    .translate({
      easing: "hardbounce",
      from: { x: 0, y: 0 },
      to: { x: 300, y: 0 },
      bounces,
      stiffness,
      duration: 10000,
      easingFn: get(fns, benzier, null),
    })
    .get();

  let df = 0;
  generatedBounces.forEach((item: { transform: string }, i: number) => {
    const t = parseInt(item.transform.split(",")[12]);
    if (benzier === "in") {
      elements.push([2 * i - 300, 300 - t].join(" "));
    } else if (benzier === "inout") {
      elements.push([2 * i - 150, 300 - t].join(" "));
    } else if (benzier === "outin") {
      if (i > 80 && i < 240) {
        df++;
      } else {
        elements.push([2.1 * i - 2.1 * df, 300 - t].join(" "));
      }
    } else {
      elements.push([2 * i, 300 - t].join(" "));
    }
  });

  return elements;
}

export function generateBounce(
  bounces: number,
  stiffness: number,
  duration: number,
  start?: number,
  end?: number,
  benzier?: string
) {
  const complex = new Complex();
  const generatedKeyframes: any = complex.translate({
    from: { x: start || 0, y: 1 },
    to: { x: end || 300, y: 1 },
    stiffness,
    easing: "hardbounce",
    duration,
    bounces,
    easingFn: get(fns, benzier || "out", null),
  });

  const offets = [...generatedKeyframes.get()].map((i) => i.offset);
  const keyframeValues = generatedKeyframes
    .get("css")
    .split(/\n/)
    .slice(1, generatedKeyframes.length)
    .map((i: any) => [parseFloat(i.split("%")), parseFloat(i.split(",")[12])])
    .filter((i: number[][]) => !Number.isNaN(i[0]));

  return { keyframeValues, offets };
}
