import React, { useMemo, memo } from "react";
import upperFirst from "lodash/upperFirst";
import get from "lodash/get";

import { styled, css } from "../../UI/helpers/theme";
import { LoopTypes, AlignTypes } from "../../../store/contants";
import { ANIMATIONS_MAP } from "../../../animations.constants";
import { Copy } from "../../UI/Copy";
import { isSpringBounce } from "../../../helpers/common";
import { LABELS } from "../../../constants";
import { CubeElement, RectElement } from "./AnimationElements";
import { FramesElement } from "./FramesElement";
import { setKeyframe } from "../../../store";
type Props = {
  kind: string;
  benzier: string;
  style: string;
  curve: number[];
  animation: string;
  align: AlignTypes;
  loop: LoopTypes;
  grey?: boolean;
  disabled?: boolean;
  ms: number;
  withGrid: boolean;
};

export const PreviewItemGrid = memo(
  ({
    kind,
    benzier,
    style,
    curve,
    animation,
    loop,
    ms,
    align,
    disabled,
    grey,
    withGrid,
  }: Props) => {
    const { currentAnimation, addStyle } = useMemo(() => {
      const found = ANIMATIONS_MAP.find((a) => a.value === animation);
      const newAnimation = found?.keyframes(
        // @ts-ignore
        kind,
        curve,
        found[align],
        align,
        ms,
        benzier
      );

      if (kind !== "linear") {
        // @ts-ignore
        setKeyframe(newAnimation?.stringifyArgs[0]);
      }

      return {
        currentAnimation: newAnimation,
        addStyle: found?.addStyle ? found?.addStyle[align] : "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animation, align, kind, curve, ms, benzier, disabled]);

    const isCube = useMemo(() => ["rotatex", "rotatey"].includes(animation), [
      animation,
    ]);

    const key = useMemo(() => loop + ms + align + benzier + style, [
      loop,
      ms,
      align,
      benzier,
      style,
    ]);

    return (
      <Wrapper>
        <HeadingTitle
          kind={kind}
          benzier={benzier}
          style={style}
          disabled={grey}
          align={align}
        />
        <GridBack align={align} withGrid={withGrid}>
          {disabled && (
            <FramesElement
              align={align}
              curve={curve}
              grey={grey}
              benzier={benzier}
            />
          )}
          {isCube && !disabled && (
            <CubeElement
              addStyle={addStyle}
              key={key}
              curve={curve}
              animation={currentAnimation}
              loop={loop === "infinity"}
              grey={grey}
              ms={ms}
            />
          )}
          {!isCube && !disabled && (
            <RectElement
              addStyle={addStyle}
              key={key}
              curve={curve}
              animation={currentAnimation}
              loop={loop === "infinity"}
              grey={grey}
              ms={ms}
              animationName={animation}
            />
          )}
        </GridBack>
      </Wrapper>
    );
  }
);

const HeadingTitle = ({
  kind,
  benzier,
  style,
  disabled,
  align,
}: {
  kind: string;
  benzier: string;
  style: string;
  disabled?: boolean;
  align: AlignTypes;
}) =>
  !disabled ? (
    <HeadingCopy display="body" color="baseWhite" type="div" bold align={align}>
      {isSpringBounce(kind) ? (
        <>
          {upperFirst(style)} {get(LABELS, kind)}
        </>
      ) : (
        <>
          {get(LABELS, benzier || kind)} {upperFirst(style)}
        </>
      )}
    </HeadingCopy>
  ) : (
    <HeadingCopy display="body" color="baseGrey2" type="div" bold align={align}>
      Linear
    </HeadingCopy>
  );

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;

const HeadingCopy = styled(Copy)<{ align: AlignTypes }>`
  margin: ${(props) =>
    props.align === AlignTypes.vertical
      ? css`10px auto 20px auto`
      : css`17.5px auto 10px 0`};
`;

const GridBack = styled.div<{ align: AlignTypes; withGrid: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.align === AlignTypes.horizontal ? 440 : 200)}px;
  height: ${(props) => (props.align === AlignTypes.horizontal ? 80 : 200)}px;
  flex-shrink: 0;
  position: relative;

  ${(props) => props.theme.screen.mobile} {
    width: ${(props) => (props.align === AlignTypes.horizontal ? 280 : 200)}px;
  }

  ${(props) =>
    props.withGrid &&
    css`
      border: 1px solid ${(props) => props.theme.colors.baseGrey3};

      background-color: transparent;
      background-image: linear-gradient(
          0deg,
          transparent 24%,
          ${(props) => props.theme.colors.baseGrey3} 25%,
          ${(props) => props.theme.colors.baseGrey3} 26%,
          transparent 27%,
          transparent 74%,
          ${(props) => props.theme.colors.baseGrey3} 75%,
          ${(props) => props.theme.colors.baseGrey3} 76%,
          transparent 77%,
          transparent
        ),
        linear-gradient(
          90deg,
          transparent 24%,
          ${(props) => props.theme.colors.baseGrey3} 25%,
          ${(props) => props.theme.colors.baseGrey3} 26%,
          transparent 27%,
          transparent 74%,
          ${(props) => props.theme.colors.baseGrey3} 75%,
          ${(props) => props.theme.colors.baseGrey3} 76%,
          transparent 77%,
          transparent
        );
      background-size: 80px 80px;
      background-position: 19px 19px;
    `}

  &:first-child {
    margin-right: 40px;
  }
`;
