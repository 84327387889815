import { keyframes, css } from "styled-components";
import { AlignTypes } from "./store/contants";
import { generateKeyframes } from "./helpers/springKeyframes";
import { generateBounce } from "./helpers/bounce";

type KindTypes = "linear" | "ease" | "spring" | "bounce";
const isMobile = window.innerWidth <= 375;

const matchKeyframes = (
  kind: KindTypes = "ease",
  curve: number[],
  keys: string[][],
  animation = "translateX",
  align: AlignTypes,
  duration: number,
  benzier: string
) => {
  if (kind === "bounce") {
    const [bounces, stiffness] = curve;
    let [[, start], [, end]] = keys;
    const unit = String(start).replace(/\d|\.|-/gim, "");
    const { keyframeValues } = generateBounce(
      bounces,
      stiffness,
      duration,
      parseFloat(start),
      parseFloat(end),
      benzier
    );

    if (["opacity"].includes(animation)) {
      return keyframeValues.map(
        ([key, value]: number[][]) =>
          `${key}% { ${animation} : ${value}${unit}; }`
      );
    }
    return keyframeValues.map(
      ([key, value]: number[][]) =>
        `${key}% { transform: ${animation}(${value}${unit}); }`
    );
  }

  if (kind === "spring") {
    const [[, start], [, end]] = keys;
    const unit = String(start).replace(/\d|\.|-/gim, "");
    const from = parseInt(start, 10);
    const to = parseInt(end, 10);
    const [tension, friction, startingVelocity] = curve;
    const generated = generateKeyframes([
      {
        tension,
        friction,
        startingVelocity,
        from,
        to,
        unit,
        property: animation,
      },
    ]);
    return generated;
  }

  if (["opacity"].includes(animation)) {
    return keys.map(([k, v]) => `${k} {${animation}: ${v};}`).join("\n");
  }

  if (["rotateX", "rotateY"].includes(animation)) {
    return keys
      .map(([k, v]) => `${k} {transform: ${animation}(${v});}`)
      .join("\n");
  }

  return keys
    .map(([k, v]) => `${k} {transform: ${animation}(${v});}`)
    .join("\n");
};

const move = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "translateX", align, duration, benzier)}
`;

const width = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "scaleX", align, duration, benzier)}
`;

const height = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "scaleY", align, duration, benzier)}
`;

const scale = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "scale", align, duration, benzier)}
`;

const rotate = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "rotate", align, duration, benzier)}
`;

const opacity = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "opacity", align, duration, benzier)}
`;

const rotateX = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "rotateX", align, duration, benzier)}
`;

const rotateY = (
  kind: KindTypes,
  curve: number[],
  keys: string[][],
  align: AlignTypes,
  duration: number,
  benzier: string
) => keyframes`
${matchKeyframes(kind, curve, keys, "rotateY", align, duration, benzier)}
`;

export const ANIMATIONS = {
  move,
  width,
  height,
  scale,
  rotate,
  opacity,
};

export const ANIMATIONS_MAP = [
  {
    label: "Move",
    keyframes: move,
    value: "move",
    defaultAlign: AlignTypes.horizontal,
    icon: "move",
    addStyle: {
      [AlignTypes.vertical]: css`
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", isMobile ? "-100px" : "-180px"],
      ["100%", isMobile ? "100px" : "180px"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "-50px"],
      ["100%", "50px"],
    ],
  },
  {
    label: "Width",
    keyframes: width,
    value: "width",
    defaultAlign: AlignTypes.horizontal,
    icon: "width",
    addStyle: {
      [AlignTypes.vertical]: css`
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", "1"],
      ["100%", isMobile ? "5" : "7.5"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "1"],
      ["100%", "2.3"],
    ],
  },
  {
    label: "Height",
    keyframes: height,
    value: "height",
    defaultAlign: AlignTypes.vertical,
    icon: "height",
    addStyle: {
      [AlignTypes.vertical]: css`
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", "0.2"],
      ["100%", "1.1"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "1"],
      ["100%", "2.3"],
    ],
  },
  {
    label: "Scale",
    keyframes: scale,
    value: "scale",
    defaultAlign: AlignTypes.vertical,
    icon: "scale",
    [AlignTypes.horizontal]: [
      ["0%", "1"],
      ["100%", "1.2"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "1.5"],
      ["100%", "3.2"],
    ],
  },
  {
    label: "Rotate",
    keyframes: rotate,
    value: "rotate",
    defaultAlign: AlignTypes.vertical,
    icon: "rotate",
    addStyle: {
      [AlignTypes.vertical]: css`
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
  },
  {
    label: "Opacity",
    keyframes: opacity,
    value: "opacity",
    defaultAlign: AlignTypes.vertical,
    icon: "opacity",
    addStyle: {
      [AlignTypes.vertical]: css`
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", 0],
      ["100%", 1],
    ],
    [AlignTypes.vertical]: [
      ["0%", 0],
      ["100%", 1],
    ],
  },
  {
    label: "Rotate X",
    keyframes: rotateX,
    value: "rotatex",
    defaultAlign: AlignTypes.vertical,
    icon: "threed",
    addStyle: {
      [AlignTypes.horizontal]: css`
        transform: scale(0.67);
        .back {
          transform: rotateY(180deg) rotateZ(180deg) translateZ(37.5px) !important;
        }
      `,
      [AlignTypes.vertical]: css`
        .back {
          transform: rotateY(180deg) rotateZ(180deg) translateZ(37.5px) !important;
        }
      `,
    },
    [AlignTypes.horizontal]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
  },
  {
    label: "Rotate Y",
    keyframes: rotateY,
    value: "rotatey",
    defaultAlign: AlignTypes.vertical,
    icon: "threed",
    addStyle: {
      [AlignTypes.horizontal]: css`
        transform: scale(0.67);
      `,
      [AlignTypes.vertical]: css``,
    },
    [AlignTypes.horizontal]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "0deg"],
      ["100%", "180deg"],
    ],
  },
  {
    label: "Frames",
    keyframes: move,
    value: "frames",
    defaultAlign: AlignTypes.horizontal,
    icon: "frames",
    [AlignTypes.horizontal]: [
      ["0%", isMobile ? "-100px" : "-180px"],
      ["100%", isMobile ? "100px" : "180px"],
    ],
    [AlignTypes.vertical]: [
      ["0%", "-50px"],
      ["100%", "50px"],
    ],
  },
];

export const ANIMATIONS_DEFAULT_ALIGN: {
  [key: string]: AlignTypes;
} = ANIMATIONS_MAP.reduce(
  (acc, crr) => ({ ...acc, [crr.value]: crr.defaultAlign }),
  {}
);
