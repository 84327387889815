import React, { memo } from "react";

import { styled } from "../UI/helpers/theme";
import { AlignTypes, LoopTypes } from "../../store/contants";
import { BlockHeading } from "../Base/BlockHeading";

import { PreviewGrids } from "./PreviewComponents/PreviewGrids";
import { PreviewControl } from "./PreviewComponents/PreviewControl";
import { AlignButtons } from "./PreviewComponents/AlignButtons";

type Props = {
  curve: number[];
  kind: string;
  benzier: string;
  style: string;
  animation: string;
  align: AlignTypes;
  loop: LoopTypes;
  ms: number;
  onSetAnimation: (value: string) => void;
  onSetAlign: (value: AlignTypes) => void;
  onSetLoop: (value: LoopTypes) => void;
  onSetMS: (value: number) => void;
  advancedMode: boolean;
};

export const Preview = memo(
  ({
    benzier,
    kind,
    style,
    curve,
    align,
    animation,
    ms,
    loop,
    onSetAnimation,
    onSetLoop,
    onSetAlign,
    onSetMS,
    advancedMode,
  }: Props) => {
    return (
      <PreviewWrapper>
        <BlockHeading
          title="2. Preview"
          right={
            advancedMode ? (
              <AlignButtons align={align} onSetAlign={onSetAlign} />
            ) : null
          }
        />
        <PreviewGrids
          align={align}
          animation={animation}
          curve={curve}
          loop={loop}
          ms={ms}
          kind={kind}
          benzier={benzier}
          style={style}
          onSetLoop={onSetLoop}
          onSetMS={onSetMS}
          advancedMode={advancedMode}
        />
        <PreviewControl
          onSetAnimation={onSetAnimation}
          animation={animation}
          withFrames={advancedMode}
        />
      </PreviewWrapper>
    );
  }
);

const PreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
`;
