import React, { useMemo } from "react";
import { styled } from "../UI/helpers/theme";
import { Copy } from "../UI/Copy";
import { BlockHeading } from "../Base/BlockHeading";
import { Button } from "../UI/Button";

type Props = {};

export const Share = (props: Props) => {
  const text = `Check out @EpicEasing — An epic easing tool to preview and generate custom animation code for CSS, JavaScript, Objective-C, and Swift. https://epiceasing.com/`;
  const generatedHref = useMemo(
    () => `https://twitter.com/intent/tweet?text=${text}`,
    [text]
  );

  return (
    <>
      <BlockHeading title="4. Share" />
      <Wrapper>
        <Copy display="h1" type="div" color="baseWhite" className="title">
          Do you like this tool?
        </Copy>
        <a href={generatedHref} target="_blank" rel="noopener noreferrer">
          <Button mode="button" icon="twitter">
            Let your friends know!
          </Button>
        </a>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;

  .title {
    margin: 10px auto;
  }

  button {
    margin: 20px auto;
  }

  ${(props) => props.theme.screen.mobile} {
    text-align: center;
  }
`;
