import React from "react";
import { Copy } from "../UI/Copy";
import { styled } from "../UI/helpers/theme";

type Props = {
  title: string;
  right?: React.ReactNode;
};

export const BlockHeading = ({ title, right }: Props) => {
  return (
    <HeadingWrapper>
      <Copy display="detail" color="baseGrey1" className="title" type="div">
        {title}
      </Copy>
      {right}
    </HeadingWrapper>
  );
};

const HeadingWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.baseGrey3};
  height: 40px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;

  .title {
    margin-right: auto;
  }
`;
