// @ts-nocheck
class Integrator {
  constructor(_accelerationForState) {
    this._accelerationForState = _accelerationForState;
    if (typeof this._accelerationForState !== "function") {
      this._accelerationForState = function () {
        return 0;
      };
    }
  }

  integrateState(state, dt) {
    let a = this._evaluateState(state);
    let b = this._evaluateStateWithDerivative(state, dt * 0.5, a);
    let c = this._evaluateStateWithDerivative(state, dt * 0.5, b);
    let d = this._evaluateStateWithDerivative(state, dt, c);
    let dxdt = (1.0 / 6.0) * (a.dx + 2.0 * (b.dx + c.dx) + d.dx);
    let dvdt = (1.0 / 6.0) * (a.dv + 2.0 * (b.dv + c.dv) + d.dv);
    state.x = state.x + dxdt * dt;
    state.v = state.v + dvdt * dt;
    return state;
  }

  _evaluateState(initialState) {
    let output = {};
    output.dx = initialState.v;
    output.dv = this._accelerationForState(initialState);
    return output;
  }

  _evaluateStateWithDerivative(initialState, dt, derivative) {
    let state = {};
    state.x = initialState.x + derivative.dx * dt;
    state.v = initialState.v + derivative.dv * dt;
    let output = {};
    output.dx = state.v;
    output.dv = this._accelerationForState(state);
    return output;
  }
}

// const spring = {
//   rk4: {
//     value: {
//       tension: 387,
//       friction: 5,
//       velocity: 0,
//     },
//   },
//   dho: {
//     value: {
//       stiffness: 200,
//       damping: 10,
//       mass: 1,
//       velocity: 0,
//     },
//   },
// };

class SpringRK4Animator {
  setup(options) {
    this.options = {
      tension: 250,
      friction: 25,
      velocity: 0,
      tolerance: 1 / 100,
      time: null,
      ...options,
    };
    this._time = 0;
    this._value = 0;
    this._velocity = this.options.velocity;
    this._stopSpring = false;
    return (this._integrator = new Integrator(
      (function (_this) {
        return function (state) {
          return (
            -_this.options.tension * state.x - _this.options.friction * state.v
          );
        };
      })(this)
    ));
  }

  next(delta) {
    if (this.finished()) {
      return 1;
    }
    this._time += delta;
    let stateBefore = {};
    let stateAfter = {};
    stateBefore.x = this._value - 1;
    stateBefore.v = this._velocity;
    stateAfter = this._integrator.integrateState(stateBefore, delta);
    this._value = 1 + stateAfter.x;
    let finalVelocity = stateAfter.v;
    let netFloat = stateAfter.x;
    let net1DVelocity = stateAfter.v;
    let netValueIsLow = Math.abs(netFloat) < this.options.tolerance;
    let netVelocityIsLow = Math.abs(net1DVelocity) < this.options.tolerance;
    this._stopSpring = netValueIsLow && netVelocityIsLow;
    this._velocity = finalVelocity;
    return this._value;
  }

  finished() {
    return this._stopSpring;
  }

  values(delta, limit) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var i, j, ref, values;
    for (
      null == delta && (delta = 1 / 60),
        null == limit && (limit = 100),
        values = [],
        i = j = 0,
        ref = limit;
      (0 <= ref ? j <= ref : j >= ref) &&
      (values.push(this.next(delta)), !this.finished());
      i = 0 <= ref ? ++j : --j
    );
    return values;
  }
}

export const getSvgPoints = (
  options = { tension: 387, friction: 5, velocity: 0 }
) => {
  const animatorClass = new SpringRK4Animator();
  animatorClass.setup(options, {
    tolerance: 1 / 10000,
  });
  const values = animatorClass.values(1 / 60, 1000);
  const svgPoints = [];

  for (const index in values) {
    const value = values[index];
    const l = values.length;
    const i = index;
    const x = parseInt(i) * (300 / l);
    const y = value * (300 / 2);
    svgPoints.push([x, 300 - y]);
  }

  return svgPoints;
};

// class SpringDHOAnimator {
//   constructor() {}
//   setup(options) {
//     this.options = {
//       velocity: 0,
//       tolerance: 1 / 10000,
//       stiffness: 50,
//       damping: 2,
//       mass: 0.2,
//       time: null,
//       ...options,
//     };
//     console.log("options", this.options, options);
//     this._time = 0;
//     this._value = 0;
//     return (this._velocity = this.options.velocity);
//   }
//   next(delta) {
//     var F_damper, F_spring, b, k;
//     if (this.finished()) {
//       return 1;
//     }
//     this._time += delta;
//     k = 0 - this.options.stiffness;
//     b = 0 - this.options.damping;
//     F_spring = k * (this._value - 1);
//     F_damper = b * this._velocity;
//     this._velocity += ((F_spring + F_damper) / this.options.mass) * delta;
//     this._value += this._velocity * delta;
//     return this._value;
//   }

//   values(delta, limit) {
//     var i, j, ref, values;
//     for (
//       null == delta && (delta = 1 / 60),
//         null == limit && (limit = 100),
//         values = [],
//         i = j = 0,
//         ref = limit;
//       (0 <= ref ? j <= ref : j >= ref) &&
//       (values.push(this.next(delta)), !this.finished());
//       i = 0 <= ref ? ++j : --j
//     );
//     return values;
//   }

//   finished() {
//     return this._time > 0 && Math.abs(this._velocity) < this.options.tolerance;
//   }
// }
