import * as React from "react";

export const SpringGrid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={300}
    height={300}
    viewBox="0 0 300 300"
    fill="none"
    style={{ zIndex: -1 }}
    {...props}
  >
    <path d="M0 300V0h300v300z" fill="transparent" />
    <path
      d="M75 300V0m75 300V0m75 300V0M0 225h299M0 150h299M0 75h299"
      stroke="#292E3D"
    />
  </svg>
);
