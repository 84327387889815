import React from "react";
import { styled } from "../../UI/helpers/theme";
import { PRESETS } from "../../../constants";
import { LoopTypes, AlignTypes } from "../../../store/contants";
import { PreviewItemGrid } from "./PreviewItemGrid";
import { SpeedControl } from "./SpeedControl";

type Props = {
  kind: string;
  benzier: string;
  style: string;
  animation: string;
  loop: LoopTypes;
  ms: number;
  curve: number[];
  align: AlignTypes;
  onSetLoop: (value: LoopTypes) => void;
  onSetMS: (value: number) => void;
  advancedMode: boolean;
};

export const PreviewGrids = ({
  align,
  kind,
  benzier,
  style,
  onSetLoop,
  onSetMS,
  animation,
  ms,
  loop,
  curve,
  advancedMode,
}: Props) => {
  const disabled = animation === "frames";

  return (
    <Wrapper>
      <AnimationPreview align={align}>
        <PreviewItemGrid
          disabled={disabled}
          align={align}
          animation={animation}
          curve={PRESETS.linear}
          loop={loop}
          kind="linear"
          benzier={benzier}
          style={style}
          ms={ms}
          grey
          withGrid={advancedMode}
        />
        <PreviewItemGrid
          disabled={disabled}
          align={align}
          animation={animation}
          curve={curve}
          loop={loop}
          kind={kind}
          benzier={benzier}
          style={style}
          ms={ms}
          withGrid={advancedMode}
        />
      </AnimationPreview>
      <SpeedControl
        ms={ms}
        loop={loop}
        onSetLoop={onSetLoop}
        onSetMS={onSetMS}
        disabled={disabled}
        withLoopControl={advancedMode}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.screen.tablet} {
    flex-wrap: wrap;
  }
`;

const AnimationPreview = styled.div<{ align: AlignTypes }>`
  display: flex;
  margin: 0 auto;
  padding-top: 10px;
  margin-bottom: 50px;
  flex-direction: ${(props) =>
    props.align === AlignTypes.horizontal ? "column" : "row"};

  ${(props) => props.theme.screen.tablet} {
    order: 2;
  }

  ${(props) => props.theme.screen.mobile} {
    flex-direction: column;
  }
`;
