import { createStore } from "effector";

import { LoopTypes, AlignTypes } from "./contants";
import { PRESETS } from "../constants";

export const $url = createStore<any>({});

export const $advancedMode = createStore(true);

export const $curve = createStore(PRESETS.ease_in_sine);
export const $draggingCurve = createStore(false);

export const $recipe = createStore<{
  kind: string;
  benzier: string;
  style: string;
}>({
  kind: "ease",
  benzier: "in",
  style: "sine",
});

export const $preview = createStore<{
  animation: string;
  align: AlignTypes;
  loop: LoopTypes;
  ms: number;
}>({
  animation: "move",
  align: AlignTypes.vertical,
  loop: LoopTypes.infinity,
  ms: 1500,
});

export const $export = createStore<{
  language: string;
  exportView: "buttons" | "code";
}>({
  language: "css",
  exportView: "code",
});

export const $currentKeyframe = createStore<any>(null);
