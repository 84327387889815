import React from "react";
import { useStore } from "effector-react";

import { Recipe } from "../components/Easing/Recipe";
import { EasingEditor } from "../components/Easing/EasingEditor/EasingEditor";
import { SpringEditor } from "../components/Easing/SpringEditor/SpringEditor";
import { BounceEditor } from "../components/Easing/BounceEditor/BounceEditor";

import { Preview } from "../components/Easing/Preview";
import { Export } from "../components/Easing/Export";
import { styled } from "../components/UI/helpers/theme";
import { isSpringBounce } from "../helpers/common";
import { Share } from "../components/Easing/Share";
import {
  $recipe,
  $preview,
  $curve,
  setCurve,
  setBenzier,
  setStyle,
  setKind,
  setAnimation,
  setLoop,
  setAlign,
  setMs,
  $advancedMode,
  $export,
} from "../store";
import { useMatchUrl } from "../hooks/useMatchUrl";

export const GeneratorContainer = () => {
  useMatchUrl();
  const { benzier, style, kind } = useStore($recipe);
  const preview = useStore($preview);
  const { animation, align, loop, ms } = preview;
  const { language, exportView } = useStore($export);
  const curve = useStore($curve);
  const advancedMode = useStore($advancedMode);

  return (
    <SidesWrapper>
      <LeftSide>
        {!isSpringBounce(kind) && (
          <EasingEditor
            value={curve}
            onBezierChange={setCurve}
            disabled={kind === "linear"}
            advancedMode={advancedMode}
            animation={animation}
          />
        )}
        {kind === "spring" && (
          <SpringEditor
            value={curve}
            onChange={setCurve}
            style={style}
            advancedMode={advancedMode}
          />
        )}
        {kind === "bounce" && (
          <BounceEditor
            value={curve}
            benzier={benzier}
            onChange={setCurve}
            advancedMode={advancedMode}
          />
        )}
      </LeftSide>
      <RightSide>
        <Recipe
          kind={kind}
          benzier={benzier}
          style={style}
          onSetBenzier={setBenzier}
          onSetStyle={setStyle}
          onSetKind={setKind}
          advancedMode={advancedMode}
        />
        <Preview
          advancedMode={advancedMode}
          onSetAnimation={setAnimation}
          onSetLoop={setLoop}
          onSetAlign={setAlign}
          onSetMS={setMs}
          kind={kind}
          curve={curve}
          benzier={benzier}
          style={style}
          animation={animation}
          align={align}
          loop={loop}
          ms={ms}
        />
        <Export
          language={language}
          exportView={exportView}
          kind={kind}
          curve={curve}
          benzier={benzier}
          style={style}
          advancedMode={advancedMode}
          preview={preview}
        />
        <Share />
      </RightSide>
    </SidesWrapper>
  );
};

const SidesWrapper = styled.div`
  display: flex;
  min-height: 100vh;

  ${(props) => props.theme.screen.tablet} {
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }

  ${(props) => props.theme.screen.mobile} {
    max-width: initial;
  }
`;

const LeftSide = styled.aside`
  width: 300px;
  margin-right: 50px;

  ${(props) => props.theme.screen.tablet} {
    margin: 50px auto;
  }
  ${(props) => props.theme.screen.mobile} {
    margin: 10px auto 50px auto;
  }
`;

const RightSide = styled.aside`
  display: flex;
  flex-direction: column;
`;
