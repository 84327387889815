import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { styled } from "../UI/helpers/theme";
import { Copy, label } from "../UI/Copy";
import { Button } from "../UI/Button";
import { useStore } from "effector-react";
import { $advancedMode, setBeginnerMode, setAdvancedMode } from "../../store";

export const TopNav = () => {
  const isAdvancedMode = useStore($advancedMode);

  return (
    <TopWrapper>
      <Link to="/">
        <Logo width={175} height={60} />
      </Link>
      <Copy display="body" color="baseGrey1" className="subheading">
        Easing made easy.
      </Copy>
      <Tabs>
        <Tab active={!isAdvancedMode} icon="simple" onClick={setBeginnerMode}>
          Beginner
        </Tab>
        <Tab active={isAdvancedMode} icon="advanced" onClick={setAdvancedMode}>
          Advanced
        </Tab>
      </Tabs>
      <WideBackground />
    </TopWrapper>
  );
};

const TopWrapper = styled.nav`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  z-index: 999;
  box-shadow: 0 8px 14px 0 black;
  margin-bottom: 10px;

  .subheading {
    margin-left: 30px;
  }

  .contact-element {
    margin-left: auto;
  }

  ${(props) => props.theme.screen.tablet} {
    padding: 10px 10px;
  }

  ${(props) => props.theme.screen.mobile} {
    position: relative;
    padding: 10px 0;
    margin-bottom: 0;

    .subheading {
      display: none;
    }
  }
`;

const WideBackground = styled.div`
  width: 100vw;
  height: 100%;
  background-color: black;
  position: absolute;
  left: -50vw;
  margin-left: 50%;
  z-index: -1;

  ${(props) => props.theme.screen.mobile} {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 48px;
`;

const Tab = styled(Button).attrs({ mode: "button" })<{ onClick: any }>`
  ${label}
  border: none;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  height: 100%;

  span {
    color: ${(props) =>
      props.active
        ? props.theme.colors.baseWhite
        : props.theme.colors.baseGrey2};
  }

  &:active,
  &:hover {
    border: none;
    background-color: transparent;
    span {
      color: ${(props) => props.theme.colors.baseWhite};
    }
  }

  ${(props) =>
    props.active &&
    `
      &:after {
          content: "";
          height: 2px;
          width: 100%;
          background-color: ${props.theme.colors.baseWhite};
          position: absolute;
          bottom: 0;
        }
  `}

  ${(props) => props.theme.screen.mobile} {
    span {
      display: none;
    }
    svg.icon {
      margin-right: 0;
    }
  }
`;
