import { merge } from "effector";

import get from "lodash/get";

import {
  $recipe,
  $curve,
  $preview,
  $advancedMode,
  $draggingCurve,
  $export,
  $currentKeyframe,
} from "./store";
import {
  setKind,
  setBenzier,
  setStyle,
  setCurve,
  setAnimation,
  setLoop,
  setAlign,
  setMs,
  setAdvancedMode,
  setBeginnerMode,
  draggingStart,
  draggingEnd,
  setLanguage,
  setCodeView,
  setButtonView,
  setKeyframe,
} from "./events";

import { PRESETS, KIND_LIST } from "../constants";
import { repeatOnce } from "../helpers/repeatOnce";
import { isSpringBounce, findBezier } from "../helpers/common";
import { AlignTypes, LoopTypes } from "./contants";
import { ANIMATIONS_DEFAULT_ALIGN } from "../animations.constants";

const PRESETS_VALUES = Object.values(PRESETS).map((i) => i.join(""));

export const itemPayload = (value: string, key: string) =>
  get(
    KIND_LIST.find((k) => k.value === value),
    key
  );

$curve.on(setCurve, (_, payload) => payload);
$curve.on(setKind, (_, payload) => itemPayload(payload, "curve"));

$recipe.on(setKind, (state, payload) => {
  if (itemPayload(payload, "default")) {
    return {
      ...state,
      kind: payload,
      ...itemPayload(payload, "default"),
    };
  }
  return state;
});

$recipe.on(setBenzier, (state, payload) => ({
  ...state,
  benzier: payload,
}));

$recipe.on(setStyle, (state, payload) => ({
  ...state,
  style: payload,
}));

$recipe.on(
  merge([setKind, setBenzier, setStyle]),
  ({ kind, benzier, style }) => {
    if (style === "custom") return;
    if (kind === "spring") {
      const curve = get(PRESETS, `${kind}_${benzier}_${style}`, [180, 12, 0]);
      setCurve(curve);
    } else if (kind === "bounce") {
      const curve = get(PRESETS, `${kind}_${benzier}_${style}`, [8, 2]);
      setCurve(curve);
    } else {
      const curve = get(PRESETS, `${kind}_${benzier}_${style}`, PRESETS.linear);
      setCurve(curve);
    }
  }
);

$recipe.on(setCurve, (state, payload) => {
  if (!PRESETS_VALUES.includes(payload.join(""))) {
    if (isSpringBounce(state.kind)) {
      return { ...state, style: "custom" };
    } else {
      const [x1, y1, x2, y2] = payload;
      const benzier: any = findBezier([x1, y1, x2, y2]);
      return { ...state, benzier, style: "custom" };
    }
  }

  return state;
});

$preview
  .on(setAnimation, (state, payload) => ({
    ...state,
    animation: payload,
    align:
      typeof payload === "string"
        ? ANIMATIONS_DEFAULT_ALIGN[payload]
        : state.align,
  }))
  .on(setLoop, (state, payload) => ({
    ...state,
    loop: repeatOnce(payload),
  }))
  .on(merge([setLoop, setAnimation, setStyle]), (state) => ({
    ...state,
    loop: repeatOnce(state.loop),
  }))
  .on(setAlign, (state, payload) => ({ ...state, align: payload }))
  .on(setMs, (state, payload) => ({ ...state, ms: payload }))
  .on(setBeginnerMode, (state) => ({
    ...state,
    align: AlignTypes.vertical,
  }));

$advancedMode.on(setAdvancedMode, (_, payload) => true);
$advancedMode.on(setBeginnerMode, (_, payload) => false);
$preview
  .on(setAdvancedMode, (state) => ({
    ...state,
    loop: LoopTypes.infinity,
  }))
  .on(setBeginnerMode, (state) => ({
    ...state,
    loop: LoopTypes.infinity,
  }));

$draggingCurve.on(draggingStart, () => true);
$draggingCurve.on(draggingEnd, () => false);

$preview.on(draggingEnd, (state) => {
  if (!PRESETS_VALUES.includes($curve.getState().join(""))) {
    return {
      ...state,
      loop: repeatOnce(state.loop),
    };
  }
  return state;
});

$export
  .on(setBeginnerMode, (state) => ({ ...state, exportView: "buttons" }))
  .on(setAdvancedMode, (state) => ({ ...state, exportView: "code" }))
  .on(setCodeView, (state) => ({ ...state, exportView: "code" }))
  .on(setButtonView, (state) => ({ ...state, exportView: "buttons" }))
  .on(setLanguage, (state, language) => ({
    ...state,
    language,
  }));

$currentKeyframe.on(setKeyframe, (_, payload) => payload);
