import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { LABELS } from "../constants";
export const formatCoord = (coord: number) => Number(coord).toFixed(3);

export const generateCode = (
  language: string,
  kind: string,
  benzier: string,
  style: string,
  curve: number[],
  currentKeyframe: any,
  preview: any
) => {
  switch (language) {
    case "css":
      return generateCSS(
        kind,
        benzier,
        style,
        curve,
        currentKeyframe,
        preview
      ).trim();
    case "scss":
      return generateSCSS(
        kind,
        benzier,
        style,
        curve,
        currentKeyframe,
        preview
      ).trim();
    case "swift":
      return generateSwift(
        kind,
        benzier,
        style,
        curve,
        currentKeyframe,
        preview
      ).trim();
    case "objectivec":
      return generateObjC(
        kind,
        benzier,
        style,
        curve,
        currentKeyframe,
        preview
      ).trim();
    default:
      return ``;
  }
};

const generateCSS = (
  kind: string,
  benzier: string,
  style: string,
  curve: number[],
  currentKeyframe: any,
  preview: any
) => {
  const { ms } = preview;
  const animationName = `epic-easing-${kind}-${style}`;
  const [x1, y1, x2, y2] = curve.map(formatCoord);
  if (["linear", "ease"].includes(kind)) {
    return [
      `-webkit-transition-timing-function: cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `-moz-transition-timing-function: cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `-o-transition-timing-function: cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `transition-timing-function: cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      "",
      `-webkit-transition: all 500ms cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `-moz-transition: all 500ms cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `-o-transition: all 500ms cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
      `transition: all 500ms cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});`,
    ].join("\n");
  }

  if (kind === "spring") {
    return `
@keyframes ${animationName} {${currentKeyframe
      .split(" }")
      .filter((i: string) => i !== "\n")
      .map((i: any) => `  ${i}}`)
      .join("")}
}

.epic-ease-${kind} {
  animation: ${animationName} ${ms}ms linear forwards:
}
    `;
  }

  const keyframesCode = currentKeyframe
    .split("}")
    .filter((i: string) => i !== "\n")
    .map((i: any) => `  ${i}}\n`);
  keyframesCode[0] = `\n  ${keyframesCode[0].substr(3)}`;

  return `
@keyframes ${animationName} {${keyframesCode.join("")}}

.epic-ease-${kind} {
  animation: ${animationName} ${ms}ms linear forwards:
}
`;
};

const generateSCSS = (
  kind: string,
  benzier: string,
  style: string,
  curve: number[],
  currentKeyframe: any,
  preview: any
) => {
  const { ms } = preview;
  const [x1, y1, x2, y2] = curve.map(formatCoord);
  const name =
    "$" +
    kebabCase(`${kind}-${benzier.split(/(in|out|In|Out)/).join("-")}-${style}`);
  const animationName = `epic-easing-${kind}-${style}`;

  if (["linear", "ease"].includes(kind)) {
    return `
${name}: cubic-bezier(${x1}, ${y1}, ${x2}, ${y2});

@mixin trans-easing($easing: ${name}) {
  transition-timing-function: $easing;
}

@mixin anim-easing($easing: ${name}) {
  animation-timing-function: $easing;
}`;
  }

  if (kind === "spring") {
    return `
@keyframes ${animationName} {${currentKeyframe
      .split(" }")
      .filter((i: string) => i !== "\n")
      .map((i: any) => `  ${i}}`)
      .join("")}
}

.epic-ease-${kind} {
  animation: ${animationName} ${ms}ms linear forwards:
}
    `;
  }

  const keyframesCode = currentKeyframe
    .split("}")
    .filter((i: string) => i !== "\n")
    .map((i: any) => `  ${i}}\n`);
  keyframesCode[0] = `\n  ${keyframesCode[0].substr(3)}`;

  return `
@keyframes ${animationName} {${keyframesCode.join("")}}

.epic-ease-${kind} {
  animation: ${animationName} ${ms}ms linear forwards:
}
`;
};

const generateSwift = (
  kind: string,
  benzier: string,
  style: string,
  curve: number[],
  currentKeyframe: any,
  preview: any
) => {
  const swiftTransitionMap = {
    move: "transform.translation.x",
    scale: "transform.scale",
    width: "transform.scale.x",
    height: "transform.scale.y",
    opacity: "opacity",
    rotate: "transform.rotation",
    rotatex: "transform.rotation.x",
    rotatey: "transform.rotation.y",
  };
  const [x1, y1, x2, y2] = curve.map(formatCoord);
  const { animation, ms } = preview;
  const name = camelCase(`${kind}_${benzier.split(/(in|out)/).join("_")}`);
  const animationName = `epic-easing-${kind}-${style}`;
  // tension, friction, velocity
  if (["linear", "ease"].includes(kind)) {
    return `
// Customize Your Values Here:
let keypath = "${get(swiftTransitionMap, animation, "transform.translate.x")}"
let fromValue = 0
let toValue = 150
let duration = ${ms / 1000}

// Animation - ${get(LABELS, benzier)} ${capitalize(
      style
    )} Easing (made with epiceasing.com)
let timingFunction = CAMediaTimingFunction(controlPoints: ${x1}, ${y1}, ${x2}, ${y2})
let animation = CABasicAnimation(keyPath: keypath)
animation.duration = duration
animation.timingFunction = timingFunction
animation.fromValue = fromValue
animation.toValue = toValue
myLayer.add(animation, forKey: "myEpicEasingAnimation")
    `;
  }

  if (kind === "spring") {
    return `
let springAnimation = CASpringAnimation(keyPath: "${animationName}")
springAnimation.stiffness = ${Number(x1).toFixed(1)}
springAnimation.damping = ${Number(y1).toFixed(1)}
springAnimation.initialVelocity = ${Number(x2).toFixed(1)}
springAnimation.fromValue = 0
springAnimation.toValue = 1
    `;
  }
  const keyframes = currentKeyframe.split("}").map((i: any) => {
    return [
      Number.isNaN(parseFloat(i)) ? 1 : parseFloat(i) / 100,
      i.match(/(:|\().*/gm) ? parseFloat(i.replace(/[a-zA-X)(;: ]+/g, "")) : 0,
    ];
  });
  const keyTimes = keyframes
    .slice(0, keyframes.length - 1)
    .map((i: any) => i[0])
    .join(", ");
  const values = keyframes
    .slice(0, keyframes.length - 1)
    .map((i: any) => i[1])
    .join(", ");

  return `
  let ${name} = CAKeyframeAnimation(keyPath: "${animation}")
${name}.duration = ${ms / 1000}

${name}.values = [${values}]

${name}.keyTimes = [${keyTimes}]
  `;
};

const generateObjC = (
  kind: string,
  benzier: string,
  style: string,
  curve: number[],
  currentKeyframe: any,
  preview: any
) => {
  const [x1, y1, x2, y2] = curve.map(formatCoord);
  const { animation, ms } = preview;
  const name = camelCase(`${kind}_${benzier.split(/(in|out)/).join("_")}`);
  const animationName = `epic-easing-${kind}-${style}`;
  // tension, friction, velocity
  if (["linear", "ease"].includes(kind)) {
    return `
      CAMediaTimingFunction *timing = [[CAMediaTimingFunction alloc] initWithControlPoints:${x1} :${y1} :${x2} :${y2}];
      CABasicAnimation *animation = [CABasicAnimation animationWithKeyPath:@"transform.translation.y"];
      animation.timingFunction = timing;
      animation.duration = ${ms / 1000};
      animation.toValue = [NSNumber numberWithFloat:100];
      [epicView.layer addAnimation:animation forKey:@"${animationName}"];
    `;
  }

  if (kind === "spring") {
    return `
CASpringAnimation *springAnimation = [CASpringAnimation animationWithKeyPath:@"${animation} "];
springAnimation.stiffness = ${Number(x1).toFixed(1)};
springAnimation.damping = ${Number(y1).toFixed(1)};
springAnimation.initialVelocity = ${Number(x2).toFixed(1)};

springAnimation.beginTime = CACurrentMediaTime() + 2.0;
springAnimation.fillMode = kCAFillModeBackwards;
springAnimation.fromValue = [NSValue valueWithCGPoint:CGPointMake(0, 0)];
springAnimation.toValue = [NSValue valueWithCGPoint:CGPointMake(1, 1)];
springAnimation.duration = ${ms / 1000} + animation1.settlingDuration;
springAnimation.timingFunction = [CAMediaTimingFunction functionWithName:kCAMediaTimingFunctionEaseInEaseOut];
springAnimation.removedOnCompletion = NO;
epicView.layer.position = self.view.center;

[epicView.layer addAnimation:springAnimation forKey:@"${animationName}"];
    `;
  }
  const keyframes = currentKeyframe.split("}").map((i: any) => {
    return [
      Number.isNaN(parseFloat(i)) ? 1 : parseFloat(i) / 100,
      i.match(/(:|\().*/gm) ? parseFloat(i.replace(/[a-zA-X)(;: ]+/g, "")) : 0,
    ];
  });
  const keyTimes = keyframes
    .slice(0, keyframes.length - 1)
    .map((i: any) => `@${i[0]}`)
    .join(", ");
  const values = keyframes
    .slice(0, keyframes.length - 1)
    .map((i: any) => `[NSValue valueWithCGPoint:CGPointMake(0., ${i[1]})]`)
    .join(", ");

  return `
CAKeyframeAnimation *${name} = [CAKeyframeAnimation animationWithKeyPath:@"${animationName}"];
${name}.duration = ${ms / 1000}

${name}.values = @[${values}]

${name}.keyTimes = @[${keyTimes}]

[epicView.layer addAnimation:${name} forKey:@"${animationName}"];
  `;
};
