import { Home } from "./pages/Home";
import { StyleGuide } from "./pages/StyleGuide";

export const ROUTES_PATHS = {
  home: "/",
  styleGuide: "/style-guide",
};

export const ROUTES = [
  {
    path: ROUTES_PATHS.home,
    component: Home,
    exact: true,
  },
  { path: ROUTES_PATHS.styleGuide, component: StyleGuide, exact: true },
];
