import React, { useState, useEffect } from "react";
import { styled, css } from "../../UI/helpers/theme";
import { bezierEasing } from "../../../helpers/bezierEasing";
import { AlignTypes } from "../../../store/contants";
import { spring } from "../../../helpers/springKeyframes";
import BounceEasing from "../../../libs/keyframegen/complex/easing/bounce";
import { bounceIn, bounceInOut, bounceOutIn } from "../../../helpers/bounce";
import get from "lodash/get";

type Props = {
  curve: number[];
  align: AlignTypes;
  grey?: boolean;
  benzier: string;
};

const fns = {
  out: bounceIn,
  inout: bounceInOut,
  outin: bounceOutIn,
};

const isMobile = window.innerWidth <= 375;

export const FramesElement = ({ grey, curve, align, benzier }: Props) => {
  const [frames, setFrames] = useState<number[][]>([]);
  useEffect(() => {
    const [x1, y1, x2, y2] = curve;
    let easing;
    const threshold = align === AlignTypes.horizontal ? 0.07 : 0.15;

    if (curve.length === 3) {
      easing = spring(x1, y1, 0.5, x2);
    } else if (curve.length === 2) {
      const bounce = new BounceEasing({ bounces: x1, stiffness: y1 });
      easing =
        benzier === "in"
          ? bounce.calculate
          : (t: number) =>
              get(fns, benzier, bounce.calculate)(t, bounce.calculate);
    } else {
      easing = bezierEasing(x1, y1, x2, y2);
    }

    const elements: number[][] = [];
    for (let i = 0; i < 1; i += threshold) {
      elements.push([easing(i), 1 - i]);
    }
    setFrames(elements.filter(Boolean));
  }, [curve, align, benzier]);

  return (
    <Wrapper>
      {frames.map(([posX], i) => (
        <Circle key={i} posX={posX} align={align} grey={grey} opacity={0.2} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const Circle = styled.div<{
  posX: number;
  align: AlignTypes;
  grey?: boolean;
  opacity: number;
}>`
  background-color: ${(props) =>
    props.grey ? "#6f7996" : props.theme.colors.brandBlue};
  border: 4px solid
    ${(props) =>
      props.grey
        ? props.theme.colors.baseGrey1
        : props.theme.colors.brandBlueLighter};
  opacity: ${(props) => props.opacity};
  position: absolute;
  top: 50%;
  ${(props) =>
    props.align === AlignTypes.horizontal
      ? css`
          width: 30px;
          height: 30px;
          border-radius: 15px;
          transform: translate(
            ${isMobile ? props.posX * -220 + 110 : props.posX * -390 + 190}px,
            -50%
          );
        `
      : css`
          width: 50px;
          height: 50px;
          border-radius: 25px;
          transform: translate(${props.posX * -132 + 58}px, -50%);
        `}
`;
