import React, { useState, useEffect, memo } from "react";

// @ts-ignore
import { uncontrollable } from "uncontrollable";

import { BezierEditor } from "./BezierEditor";
import { styled } from "../../UI/helpers/theme";
import { Copy } from "../../UI/Copy";
import { $draggingCurve } from "../../../store";
import { useStore } from "effector-react";
import { CurveInput } from "./CurveInput";

// @ts-ignore
const BezierComponent = uncontrollable(BezierEditor, {
  value: "onChange",
});

type Props = {
  onBezierChange?: Function;
  value?: number[];
  disabled?: boolean;
  advancedMode: boolean;
  animation: string;
};

export const EasingEditor = memo(
  ({ onBezierChange, value, disabled, animation, advancedMode }: Props) => {
    const draggingCurve = useStore($draggingCurve);
    const [curve, setCurve] = useState<any>(value || [0.755, 0.295, 0.2, 0.81]);

    useEffect(() => {
      if (animation === "frames" && draggingCurve) {
        if (onBezierChange) onBezierChange(curve);
      } else {
        if (!draggingCurve) {
          if (onBezierChange) onBezierChange(curve);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBezierChange, curve, draggingCurve]);

    useEffect(() => {
      setCurve(value);
    }, [value]);

    return (
      <Wrapper>
        <BezierComponent
          onChange={setCurve}
          value={curve}
          readOnly={disabled}
          advancedMode={advancedMode}
        />
        <CurveInput curve={curve} onChange={setCurve} />
        {advancedMode && (
          <LabelsWrapper>
            <Copy display="body" color="baseGrey2">
              X1
            </Copy>
            <Copy display="body" color="baseGrey2">
              Y1
            </Copy>
            <Copy display="body" color="baseGrey2">
              X2
            </Copy>
            <Copy display="body" color="baseGrey2">
              Y2
            </Copy>
          </LabelsWrapper>
        )}
      </Wrapper>
    );
  },
  (prev, next) =>
    prev.value === next.value && prev.advancedMode === next.advancedMode
);

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 100px;

  &:hover svg path,
  &:hover svg circle,
  &:hover svg line {
    transition: 0s;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
`;
