export const isSpringBounce = (value: string) =>
  ["spring", "bounce"].includes(value);

export const isSpring = (value: string) => value === "spring";
export const isBounce = (value: string) => value === "bounce";

export const isLinear = (value: string) => value === "linear";

export const findBezier = ([x1, y1, x2, y2]: number[]) => {
  if (x1 > y1 && x2 > y2) return "in";
  else if (x1 > y1 && x2 < y2) return "inout";
  else if (x1 < y1 && x2 < y2) return "out";
  else if (x1 < y1 && x2 > y2) return "outin";
};

export const clearArray = (value: string | number): boolean => {
  const types = {
    string: (v: any): boolean | undefined => !!v,
    object: (v: any): boolean | undefined =>
      !!(v && (Array.isArray(v) ? v : Object.keys(v)).length),
    number: (v: any): boolean | undefined => !Number.isNaN(v),
  };
  return (
    types?.[typeof value as "string" | "object" | "number"]?.(value) ?? !!value
  );
};
