import React from "react";
import { styled } from "../UI/helpers/theme";
import avatar from "../../assets/meritt_avatar.png";
import { Copy } from "../UI/Copy";

type Props = {};

export const Footer = (props: Props) => {
  return (
    <FooterWrapper>
      <AuthorBadge />
    </FooterWrapper>
  );
};

const AuthorBadge = () => (
  <AuthorBadgeWrapper href="http://meritt.me" target="_blank" rel="noopener">
    <Avatar />
    <Copy display="body" color="baseWhite">
      A project by{" "}
      <Copy display="label" color="baseWhite" bold className="name">
        Meritt Thomas
      </Copy>
    </Copy>
  </AuthorBadgeWrapper>
);

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -20px 30px 0 #000;

  ${(props) => props.theme.screen.tablet} {
    position: relative;
    padding-bottom: 0;
  }

  ${(props) => props.theme.screen.mobile} {
    flex-direction: column;
  }
`;

const AuthorBadgeWrapper = styled.a`
  padding: 10px 17px 10px 3px;
  border: 1px solid ${(props) => props.theme.colors.baseGrey2};
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 50px;
  display: flex;
  align-items: center;
  outline: none;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.baseGrey1};
  }

  &:active {
    border: 1px solid ${(props) => props.theme.colors.baseWhite};
  }

  ${(props) => props.theme.screen.tablet} {
    margin-left: 10px;
  }

  ${(props) => props.theme.screen.mobile} {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    margin: 0;
  }
`;

const Avatar = styled.div`
  width: 30px;
  height: 30px;
  background: linear-gradient(180deg, rgba(60, 27, 254, 0) 72.93%, #3c1bfe 100%),
    url(${avatar});
  background-size: cover;
  mix-blend-mode: normal;
  border-radius: 50px;
  margin: 0 10px;
`;
