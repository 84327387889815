import BounceEasing from "./bounce";

// "bounce-in": bounce,
// "bounce-out": reverse(bounce),
// "bounce-in-out": reflect(bounce),
// "bounce-out-in": reflect(reverse(bounce))

class HardBounceEasing extends BounceEasing {
  oscillation = (t: number): number => Math.abs(Math.cos(this.omega * t));
}

export default HardBounceEasing;
