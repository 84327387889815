import React from "react";
import Slider from "rc-slider/lib/Slider";
import Handle from "rc-slider/lib/Handle";
import { styled } from "./helpers/theme";

type Props = {
  value?: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  prefixCls?: string;
  onChange?: (value: number) => void;
  onBeforeChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
  vertical?: boolean;
  included?: boolean;
  disabled?: boolean;
  reverse?: boolean;
  minimumTrackStyle?: React.CSSProperties;
  trackStyle?: React.CSSProperties;
  handleStyle?: React.CSSProperties;
  tabIndex?: number;
  ariaLabelForHandle?: string;
  ariaLabelledByForHandle?: string;
  ariaValueTextFormatterForHandle?: string;
  startPoint?: number;
};

const UpdatedHandle = (p: any) => <StyledHandle {...p} />;

export const InputSlider = (props: Props) => {
  return <SliderWrapper {...props} handle={UpdatedHandle} />;
};

// @ts-ignore
const SliderWrapper = styled(Slider)`
  .rc-slider-rail {
    height: 4px;
    background-color: ${(props) => props.theme.colors.baseGrey3};
  }
  .rc-slider-track {
    height: 4px;
    background-image: ${(props) => props.theme.gradients.brandGradient};
  }
`;

const StyledHandle = styled(Handle)`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 6px solid ${(props) => props.theme.colors.brandBlue};
  background-color: white;
  margin-top: -11px;

  &:hover,
  &:focus,
  &:active {
    border-color: ${(props) => props.theme.colors.brandBlue};
    box-shadow: none;
  }
`;
