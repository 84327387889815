import React, { useEffect } from "react";
import { BounceCurve } from "./BounceCurve";
import { SpringInput } from "../SpringEditor/SpringInput";
import { styled } from "../../UI/helpers/theme";

type Props = {
  value: number[];
  onChange: (value: number[]) => void;
  benzier: string;
  advancedMode: boolean;
};

export const BounceEditor = ({
  onChange,
  benzier,
  value,
  advancedMode,
}: Props) => {
  const [bounces, stiffness] = value;

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Wrapper>
      <BounceCurve
        curveColor="url(#gradient)"
        curveWidth={5}
        value={value}
        benzier={benzier}
        withGrid={advancedMode}
      />
      <SpringInput
        max={10}
        min={1}
        step={1}
        label="Bounces"
        value={bounces}
        onChange={(t) => onChange([t, value[1]])}
        tabIndex={12}
      />
      <SpringInput
        max={5}
        min={0.1}
        step={0.1}
        label="Stiffness"
        value={stiffness}
        onChange={(t) => onChange([value[0], t])}
        tabIndex={12}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 100px;
`;
