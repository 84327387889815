export const PRESETS = {
  ease_in_back: [0.6, -0.28, 0.735, 0.045],
  ease_in_circ: [0.6, 0.04, 0.98, 0.335],
  ease_in_cubic: [0.55, 0.055, 0.675, 0.19],
  ease_in_epic: [0.25, 0.0, 0.75, 0.25],
  ease_in_expo: [0.95, 0.05, 0.795, 0.035],
  ease_inout_back: [0.68, -0.55, 0.265, 1.55],
  ease_inout_circ: [0.785, 0.135, 0.15, 0.86],
  ease_inout_cubic: [0.645, 0.045, 0.355, 1],
  ease_inout_epic: [0.75, 0.25, 0.25, 0.75],
  ease_inout_expo: [1, 0, 0, 1],
  ease_inout_quad: [0.455, 0.03, 0.515, 0.955],
  ease_inout_quart: [0.77, 0, 0.175, 1],
  ease_inout_quint: [0.86, 0, 0.07, 1],
  ease_inout_sine: [0.445, 0.05, 0.55, 0.95],
  ease_inout: [0.42, 0.0, 0.58, 1.0],
  ease_in_quad: [0.55, 0.085, 0.68, 0.53],
  ease_in_quart: [0.895, 0.03, 0.685, 0.22],
  ease_in_quint: [0.755, 0.05, 0.855, 0.06],
  ease_in_sine: [0.47, 0, 0.745, 0.715],
  ease_in: [0.42, 0.0, 1.0, 1.0],
  ease_out_back: [0.175, 0.885, 0.32, 1.275],
  ease_out_circ: [0.075, 0.82, 0.165, 1],
  ease_out_cubic: [0.215, 0.61, 0.355, 1],
  ease_out_epic: [0.25, 0.75, 0.75, 1.0],
  ease_out_expo: [0.19, 1, 0.22, 1],
  ease_outin_back: [0.25, 1.25, 0.75, -0.25],
  ease_outin_circ: [0.135, 0.785, 0.86, 0.15],
  ease_outin_cubic: [0.045, 0.645, 1, 0.355],
  ease_outin_epic: [0.25, 0.75, 0.75, 0.25],
  ease_outin_expo: [0, 1, 1, 0],
  ease_outin_quad: [0.03, 0.455, 0.955, 0.515],
  ease_outin_quart: [0, 0.77, 1, 0.175],
  ease_outin_quint: [0, 0.86, 1, 0.07],
  ease_outin_sine: [0.05, 0.445, 0.95, 0.55],
  ease_outin: [0, 0.42, 1, 0.58],
  ease_out_quad: [0.25, 0.46, 0.45, 0.94],
  ease_out_quart: [0.165, 0.84, 0.44, 1],
  ease_out_quint: [0.23, 1, 0.32, 1],
  ease_out_sine: [0.39, 0.575, 0.565, 1],
  ease_out: [0.0, 0.0, 0.58, 1.0],
  ease: [0.25, 0.1, 0.25, 1.0],
  linear: [0.25, 0.25, 0.75, 0.75],
  spring_in_relaxed: [150, 10, 5],
  spring_in_loose: [180, 10, 0],
  spring_in_sling: [180, 10, 10],
  spring_in_floppy: [220, 10, 20],
  spring_in_easy: [150, 20, 10],
  spring_in_gentle: [120, 14, 0],
  spring_in_alert: [300, 30, 10],
  spring_in_stiff: [200, 20, 0],
  spring_in_snap: [150, 20, 20],
  spring_in_slow: [280, 60, 0],
  spring_in_swift: [350, 50, 30],

  bounce_in_floppy: [6, 0.1],
  bounce_inout_floppy: [6, 0.1],
  bounce_out_floppy: [6, 0.1],
  bounce_outin_floppy: [6, 0.1],
  bounce_in_alert: [4, 4],
  bounce_inout_alert: [4, 4],
  bounce_out_alert: [4, 4],
  bounce_outin_alert: [4, 4],
  bounce_in_slow: [1, 0.1],
  bounce_inout_slow: [1, 0.1],
  bounce_out_slow: [1, 0.1],
  bounce_outin_slow: [1, 0.1],
  bounce_in_loose: [3, 0.1],
  bounce_inout_loose: [3, 0.1],
  bounce_out_loose: [3, 0.1],
  bounce_outin_loose: [3, 0.1],
  bounce_in_easy: [3, 3],
  bounce_inout_easy: [3, 3],
  bounce_out_easy: [3, 3],
  bounce_outin_easy: [3, 3],
  bounce_in_stiff: [8, 5],
  bounce_inout_stiff: [8, 5],
  bounce_out_stiff: [8, 5],
  bounce_outin_stiff: [8, 5],
  bounce_in_swift: [4, 2],
  bounce_inout_swift: [4, 2],
  bounce_out_swift: [4, 2],
  bounce_outin_swift: [4, 2],
  bounce_in_sling: [6, 2],
  bounce_inout_sling: [6, 2],
  bounce_out_sling: [6, 2],
  bounce_outin_sling: [6, 2],
  bounce_in_gentle: [3, 1.5],
  bounce_inout_gentle: [3, 1.5],
  bounce_out_gentle: [3, 1.5],
  bounce_outin_gentle: [3, 1.5],
  bounce_in_snap: [4, 5],
  bounce_inout_snap: [4, 5],
  bounce_out_snap: [4, 5],
  bounce_outin_snap: [4, 5],
  bounce_in_relaxed: [4, 0.2],
  bounce_inout_relaxed: [4, 0.2],
  bounce_out_relaxed: [4, 0.2],
  bounce_outin_relaxed: [4, 0.2],
};

const EASING_STYLES = [
  "sine",
  "quad",
  "cubic",
  "quart",
  "quint",
  "expo",
  "circ",
  "back",
  "epic",
];

const SPRING_STYLES = [
  "relaxed",
  "loose",
  "sling",
  "floppy",
  "easy",
  "gentle",
  "alert",
  "stiff",
  "snap",
  "slow",
  "swift",
];

const BOUNCE_STYLES = [
  "floppy",
  "alert",
  "slow",
  "loose",
  "easy",
  "stiff",
  "swift",
  "sling",
  "gentle",
  "snap",
  "relaxed",
];

export const EASING_MAP = {
  linear: {
    in: EASING_STYLES,
    out: EASING_STYLES,
    inout: EASING_STYLES,
    outin: EASING_STYLES,
  },
  ease: {
    in: EASING_STYLES,
    out: EASING_STYLES,
    inout: EASING_STYLES,
    outin: EASING_STYLES,
  },
  spring: {
    in: SPRING_STYLES,
  },
  bounce: {
    in: BOUNCE_STYLES,
    out: BOUNCE_STYLES,
    inout: BOUNCE_STYLES,
    outin: BOUNCE_STYLES,
  },
};

export const LABELS: object = {
  linear: "Linear",
  ease: "Ease",
  spring: "Spring",
  bounce: "Bounce",
  in: "In",
  out: "Out",
  inout: "In Out",
  outin: "Out In",
};

export const BEGINNER_STYLES_DESC = {
  sine: "Wave",
  quad: "Power of 2",
  cubic: "Power of 3",
  quart: "Power of 4",
  quint: "Power of 5",
  expo: "Use your powers for good",
  circ: "Circle",
  back: "Not the front",
  epic: "Like our logo",
  gentle: "Gentle",
  relaxed: "Relaxed",
  loose: "Loose",
  sling: "Sling",
  floppy: "Floppy",
  easy: "Easy",
  alert: "Alert",
  stiff: "Stiff",
  snap: "Snap",
  slow: "Slow",
  swift: "Swift",
  custom: "You do you, boo",
};

export const ADVANCED_STYLES_DESC = {
  gentle: "Gentle",
  relaxed: "Relaxed",
  loose: "Loose",
  sling: "Sling",
  floppy: "Floppy",
  easy: "Easy",
  alert: "Alert",
  stiff: "Stiff",
  snap: "Snap",
  slow: "Slow",
  swift: "Swift",
  linear: "Linear interpolation ☠️",
  sine: "Sinusoidal",
  quad: "Quadratic",
  cubic: "Third-degree",
  quart: "Quartic",
  quint: "Quintic",
  expo: "Exponential",
  back: "Overshot cubic",
  circ: "Circular arc",
  bounce: "Exponential decay",
  elastic: "Exponentially-damped sinusoid",
  epic: "Like our logo, kind of",
  custom: "You do you, boo",
};

export const BEZIER = [
  {
    value: "in",
    label: "In",
    icon: "ease_in",
  },
  {
    value: "out",
    label: "Out",
    icon: "ease_out",
  },
  {
    value: "inout",
    label: "In Out",
    icon: "ease_inout",
  },
  {
    value: "outin",
    label: "Out In",
    icon: "ease_outin",
  },
];

export const KIND_LIST = [
  {
    value: "linear",
    label: "Linear",
    icon: "linear",
    default: {
      kind: "linear",
      benzier: "",
      style: "",
    },
    curve: PRESETS.linear,
  },
  {
    value: "ease",
    label: "Ease",
    icon: "ease_in",
    default: {
      kind: "ease",
      benzier: "in",
      style: "sine",
    },
    curve: PRESETS.ease_in_sine,
  },
  {
    value: "spring",
    label: "Spring",
    icon: "spring",
    default: {
      kind: "spring",
      benzier: "in",
      style: "relaxed",
    },
    curve: PRESETS.spring_in_relaxed,
  },
  {
    value: "bounce",
    label: "Bounce",
    icon: "bounce",
    default: {
      kind: "bounce",
      benzier: "out",
      style: "floppy",
    },
    curve: PRESETS.bounce_in_floppy,
  },
];
