import React, { useMemo } from "react";
import { ANIMATIONS_MAP } from "../../../animations.constants";
import { Button } from "../../UI/Button";
import { styled } from "../../UI/helpers/theme";

type Props = {
  onSetAnimation: (value: string) => void;
  animation: any;
  withFrames: boolean;
};

export const PreviewControl = ({
  onSetAnimation,
  animation,
  withFrames = true,
}: Props) => {
  const styles = useMemo(
    () =>
      withFrames
        ? ANIMATIONS_MAP
        : ANIMATIONS_MAP.filter((a) => a.value !== "frames"),
    [withFrames]
  );

  return (
    <ButtonsWrapper>
      {styles.map(({ value, label, icon }) => (
        <Button
          key={value}
          onClick={() => onSetAnimation(value)}
          active={value === animation}
          icon={icon}
        >
          {label}
        </Button>
      ))}
    </ButtonsWrapper>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin-right: 10px;
    margin-bottom: 10px;

    ${(props) => props.theme.screen.mobile} {
      margin-right: 2px;
    }
  }
`;
