import React from "react";
import { Copy } from "../components/UI/Copy";
import { styled } from "../components/UI/helpers/theme";
import { Button } from "../components/UI/Button";
import { BaseLayout } from "../components/Base/BaseLayout";
import { ICONS, Icon } from "../components/UI/Icon";

type Props = {};

export const StyleGuide = (props: Props) => {
  return (
    <BaseLayout>
      <Container>
        <Copy type="div" display="display" color="baseWhite">
          <Copy display="detail">Display</Copy> Linear
        </Copy>
        <Copy type="div" display="h1" color="baseWhite">
          <Copy display="detail">H1</Copy> Easing made easy.
        </Copy>
        <Copy type="div" display="h2" color="brandPink">
          <Copy display="detail">H2</Copy> 0.250, 0.250, 0.750, 0.750
        </Copy>
        <Copy type="div" display="h3" color="baseWhite">
          <Copy display="detail">H3</Copy> Linear
        </Copy>
        <Copy type="div" display="body" color="baseGrey1">
          <Copy display="detail">Body</Copy> Easing made easy.
        </Copy>
        <Copy type="div" display="detail" color="baseGrey1">
          <Copy display="detail">Detail</Copy>
          1. Recipe
        </Copy>
        <Copy type="div" display="label" color="baseWhite">
          <Copy display="detail">Label</Copy> Linear
        </Copy>
      </Container>
      <Container style={{ display: "flex" }}>
        <Button>Speed</Button>
        <Button icon="quad">Quad</Button>
        <Button icon="quad" active>
          Quad (Active)
        </Button>
        <Button primary>Get Started</Button>
      </Container>
      <Container style={{ display: "flex", flexWrap: "wrap" }}>
        {Object.keys(ICONS).map((icon: any) => (
          <div key={icon} className="icon">
            <Icon name={icon} color="url(#gradient)" />
            <Copy type="div" color="baseWhite" display="label">
              {icon}
            </Copy>
          </div>
        ))}
      </Container>
    </BaseLayout>
  );
};

const Container = styled.div`
  display: block;
  margin-bottom: 64px;
  > div {
    margin: 32px 0;
    display: flex;
    align-items: center;
    span {
      width: 100px;
    }
  }

  button {
    margin-right: 16px;
  }

  .icon {
    display: flex;
    flex-direction: column;

    div {
      margin: 16px;
    }
  }
`;
