import baseStyled, {
  ThemedStyledInterface,
  ThemedCssFunction,
  css as styledCss,
  keyframes as styledKeyframes,
} from "styled-components";

export const theme = {
  colors: {
    brandBlue: "#0094FF",
    brandBlueLighter: "#00AAFF",
    brandPink: "#FF00FF",
    brandPinkDarker: "#CA00CA",
    brandPurple: "#6633FF",
    baseGreen: "#27AE60",
    baseBlack: "#000000",
    baseWhite: "#FFFFFF",
    baseGrey1: "#858FAD",
    baseGrey2: "#525C7A",
    baseGrey3: "#292E3D",
    baseGrey4: "#14171F",
  },
  gradients: {
    brandGradient:
      "linear-gradient(90deg, #FF00FF 0%, #6633FF 48.44%, #0094FF 100%)",
  },
  fontFamily:
    '"HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  screen: {
    desktop: `@media (max-width: 1152px)`,
    tablet: `@media (max-width: 768px)`,
    mobile: "@media (max-width: 375px)",
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = styledCss as ThemedCssFunction<Theme>;
export const keyframes = styledKeyframes;
