import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "querystring";
import { matchUrlState } from "../store";

export const useMatchUrl = () => {
  const location = useLocation();

  useEffect(() => {
    const query = parse(
      window.decodeURIComponent(location.search.substr(1))
    ) as {
      [key: string]: string;
    };
    matchUrlState(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
