import React from "react";
import { InputSlider } from "../../UI/Slider";
import { styled } from "../../UI/helpers/theme";
import { Copy, label } from "../../UI/Copy";

type Props = {
  value: number;
  onChange: (value: number) => void;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  tabIndex?: number;
};

export const SpringInput = ({
  value,
  onChange,
  min,
  max,
  step,
  label,
  tabIndex,
}: Props) => (
  <Wrapper>
    <InputWrapper>
      <Copy display="body" color="baseGrey1">
        {label}
      </Copy>
      <InputSlider
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
    </InputWrapper>
    <DisplayWrapper>
      <Input
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        tabIndex={tabIndex}
        max={max}
        min={min}
        step={step}
      />
    </DisplayWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  user-select: none;
`;

const InputWrapper = styled.div`
  min-width: 200px;
  flex-grow: 1;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 8px;

  span {
    display: block;
    margin-bottom: 10px;
  }
`;

const DisplayWrapper = styled.div`
  height: 100%;
`;

const Input = styled.input`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.baseGrey2};
  color: white;
  height: 42px;
  border-radius: 5px;
  padding: 10px 10px;
  text-align: center;
  width: 100%;
  ${label}

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.baseGrey1};
  }
`;
