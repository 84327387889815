import React, { useState, useRef, useCallback } from "react";
import { styled, css } from "./helpers/theme";
import { label, Copy } from "./Copy";

type Props = {
  value: any;
  onChange: (value: number | string) => void;
  max: number;
  min: number;
  step: number;
  tabIndex?: number;
  suffix?: string;
  disabled?: boolean;
};

export const NumberInput = ({
  value,
  onChange,
  max,
  min,
  step,
  tabIndex,
  suffix,
  disabled,
  ...props
}: Props) => {
  const [focused, setFocus] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const onClick = () => {
    ref.current?.select();
  };
  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => setFocus(false);
  const handler = useCallback(
    (e) => {
      const value = parseInt(e.target.value);
      if (!value) onChange(min);
      else if (value <= max && value >= min) {
        onChange(parseInt(e.target.value, 10));
      }
    },
    [max, min, onChange]
  );

  return (
    <InputWrapper
      focused={focused}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <Input
        ref={ref}
        type="number"
        value={value}
        onChange={handler}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={tabIndex}
        max={max}
        min={min}
        step={step}
        disabled={disabled}
      />
      {suffix && (
        <Suffix display="body" color="baseGrey2">
          {suffix}
        </Suffix>
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div<{ focused: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.baseGrey2};
  height: 42px;
  border-radius: 5px;
  padding: 10px 10px;
  width: 100%;
  cursor: text;

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${(props) => props.theme.colors.baseGrey3};
    `}

  ${(props) =>
    props.focused &&
    css`
      border: 1px solid ${(props) => props.theme.colors.baseGrey1};
    `}
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  text-align: center;
  width: ${(props) => String(props.value).length * 12}px;
  ${label}

  &:disabled {
    color: ${(props) => props.theme.colors.baseGrey3};
    user-select: none;
  }

  &:focus {
    border: 0;
  }
`;

const Suffix = styled(Copy)`
  user-select: none;
`;
