import React from "react";
import { Button } from "../../UI/Button";
import { AlignTypes } from "../../../store/contants";

type Props = {
  align: AlignTypes;
  onSetAlign: (value: AlignTypes) => void;
};

export const AlignButtons = ({ align, onSetAlign }: Props) => {
  return (
    <>
      <Button
        mode="tiny"
        icon="alignH"
        active={align === AlignTypes.horizontal}
        onClick={() => onSetAlign(AlignTypes.horizontal)}
      />
      <Button
        mode="tiny"
        icon="alignV"
        active={align === AlignTypes.vertical}
        onClick={() => onSetAlign(AlignTypes.vertical)}
      />
    </>
  );
};
