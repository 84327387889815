import React from "react";
import { BaseLayout } from "../components/Base/BaseLayout";
import { GeneratorContainer } from "../containers/GeneratorContainer";

type Props = {};

export const Home = (props: Props) => {
  return (
    <BaseLayout>
      <GeneratorContainer />
    </BaseLayout>
  );
};
