import React, { memo } from "react";
import { Copy } from "../../UI/Copy";
import { KIND_LIST } from "../../../constants";
import { Button } from "../../UI/Button";
import { styled } from "../../UI/helpers/theme";

type Props = {
  kind: string;
  onClick: (value: string) => void;
  title: string;
};

export const KindControl = memo(({ kind, title, onClick }: Props) => {
  return (
    <KindsWrapper>
      <Copy display="detail" type="div" color="baseGrey1" className="title">
        {title}
      </Copy>
      {KIND_LIST.map((item) => (
        <Button
          key={item.value}
          icon={item.icon}
          active={item.value === kind}
          onClick={() => onClick(item.value)}
          gradient
        >
          {item.label}
        </Button>
      ))}
    </KindsWrapper>
  );
});

const KindsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 16px;
  margin-right: 16px;
  align-items: baseline;
  border-right: 1px solid ${(props) => props.theme.colors.baseGrey3};
  padding-top: 30px;
  position: relative;

  .title {
    top: 0;
    position: absolute;
  }

  ${(props) => props.theme.screen.tablet} {
    flex-direction: row;
    border-right: 0;
    flex-wrap: wrap;
    button {
      margin-right: 2px;
    }
  }
`;
