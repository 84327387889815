import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  lazy,
  Suspense,
} from "react";
// @ts-ignore
import copy from "copy-text-to-clipboard";
import { styled, keyframes } from "../UI/helpers/theme";
import { Copy } from "../UI/Copy";

import { generateCode } from "../../helpers/generateCode";
import { Button } from "../UI/Button";
import { BlockHeading } from "../Base/BlockHeading";
import {
  setLanguage,
  setCodeView,
  setButtonView,
  $currentKeyframe,
} from "../../store";
import { useStore } from "effector-react";
import { syntaxTheme } from "../UI/helpers/syntaxTheme";

// @ts-ignore
import css from "react-syntax-highlighter/dist/esm/languages/prism/css-extras";
// @ts-ignore
import scss from "react-syntax-highlighter/dist/esm/languages/prism/scss";
// @ts-ignore
import swift from "react-syntax-highlighter/dist/esm/languages/prism/swift";
// @ts-ignore
import objectivec from "react-syntax-highlighter/dist/esm/languages/prism/objectivec";

const SyntaxHighlighter = lazy(() =>
  // @ts-ignore
  import("react-syntax-highlighter/dist/esm/prism-light").then((component) => {
    component.default.registerLanguage("css", css);
    component.default.registerLanguage("scss", scss);
    component.default.registerLanguage("swift", swift);
    component.default.registerLanguage("objectivec", objectivec);
    return component;
  })
);

type Props = {
  language: string;
  exportView: "buttons" | "code";
  curve: number[];
  kind: string;
  benzier: string;
  style: string;
  advancedMode: boolean;
  preview: any;
};

const LANGUAGES = [
  { value: "css", label: "CSS", icon: "css", iconActive: "cssActive" },
  { value: "scss", label: "SCSS", icon: "scss", iconActive: "scssActive" },
  { value: "swift", label: "Swift", icon: "swift", iconActive: "swiftActive" },
  {
    value: "objectivec",
    label: "Obj-C",
    icon: "apple",
    iconActive: "appleActive",
  },
];

export const Export = ({
  language,
  exportView,
  curve,
  benzier,
  style,
  kind,
  advancedMode,
  preview,
}: Props) => {
  const timerID = useRef<number | null>(null);
  const currentKeyframe = useStore($currentKeyframe);
  const generatedCode = useMemo(
    () =>
      generateCode(
        language,
        kind,
        benzier,
        style,
        curve,
        currentKeyframe || "",
        preview
      ),
    [language, kind, benzier, style, curve, currentKeyframe, preview]
  );

  const isCode = exportView === "code";
  const [copied, setCopied] = useState("");

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const onTextareaClick = useCallback(() => {
    if (textareaRef?.current) {
      textareaRef.current?.select();
    }
  }, [textareaRef]);

  const handler = (lang?: string) => {
    if (timerID?.current) clearTimeout(timerID.current);
    if (isCode && lang) {
      setLanguage(lang);
    } else {
      copy(generatedCode);
      setCopied(lang || "any");
      timerID.current = setTimeout(() => setCopied(""), 1000);
    }
  };

  useEffect(() => {
    if (exportView === "buttons") setLanguage("");
    else setLanguage(language || "css");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportView]);

  return (
    <ExportWrapper>
      <BlockHeading
        title="3. Export"
        right={
          advancedMode ? (
            <ToggleWrapper>
              <Button
                mode="tiny"
                onClick={setButtonView}
                active={!isCode}
                icon="copy"
              />
              <Button
                mode="tiny"
                onClick={setCodeView}
                active={isCode}
                icon="code"
              />
            </ToggleWrapper>
          ) : null
        }
      />
      <ButtonsWrapper>
        {LANGUAGES.map((item) => (
          <Button
            key={item.value}
            active={isCode && item.value === language}
            onClick={() => handler(item.value)}
            icon={item.value === language ? item.iconActive : item.iconActive}
            mode="button"
          >
            {isCode ? "View" : "Copy"} {item.label}
            {!isCode && copied === item.value && (
              <Copied>
                <Copy display="label" color="baseWhite">
                  Copied!
                </Copy>
              </Copied>
            )}
          </Button>
        ))}
      </ButtonsWrapper>
      {isCode && (
        <CodeWrapper>
          <Suspense fallback={<div />}>
            <SyntaxHighlighter
              ref={textareaRef}
              language={language}
              style={syntaxTheme}
              onClick={onTextareaClick}
            >
              {generatedCode}
            </SyntaxHighlighter>
          </Suspense>
          <CopyButton onClick={() => handler()} icon="copy">
            Copy
            {copied && (
              <Copied>
                <Copy display="label" color="baseWhite">
                  Copied!
                </Copy>
              </Copied>
            )}
          </CopyButton>
        </CodeWrapper>
      )}
    </ExportWrapper>
  );
};

const ExportWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
`;

const ToggleWrapper = styled.div`
  display: flex;

  button {
    margin-left: 5px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;

  button {
    margin-right: 10px;
    margin-bottom: 10px;

    ${(props) => props.theme.screen.mobile} {
      flex-grow: 1;
    }
  }
`;

const CodeWrapper = styled.div`
  width: 100%;
  max-width: 590px;
  height: auto;
  position: relative;
`;

export const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Copied = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.baseGreen};
  animation: ${opacity} 0.1s linear;
`;

const CopyButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.baseGrey3};
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: black;
`;
