import React from "react";
import { styled, css, theme } from "./helpers/theme";

type DisplayTypes =
  | "display"
  | "h1"
  | "h2"
  | "h3"
  | "body"
  | "detail"
  | "label"
  | "code";
type SolidColors = keyof typeof theme.colors;
type Gradients = keyof typeof theme.gradients;

type Props = {
  display: DisplayTypes;
  type?: string;
  color?: SolidColors;
  gradient?: Gradients;
  children: any;
  style?: any;
  className?: string;
  bold?: boolean | null;
  nowrap?: boolean;
  href?: string;
  target?: string;
};

export const Copy = ({
  display,
  type,
  color,
  bold,
  gradient,
  children,
  nowrap,
  ...rest
}: Props) => {
  return (
    <InitialCopy
      display={display}
      type={type}
      color={color}
      gradient={gradient}
      bold={bold}
      nowrap={nowrap}
      {...rest}
    >
      {children}
    </InitialCopy>
  );
};

const InitialCopy = styled(({ type, bold, ...props }: Props) => {
  delete props?.nowrap;
  return React.createElement(type || "span", props);
})`
  font-family: ${(props) => props.theme.fontFamily};

  ${(props) => props.display === "display" && display};
  ${(props) => props.display === "h1" && h1}
  ${(props) => props.display === "h2" && h2}
  ${(props) =>
    props.display === "h3" && h3}
  ${(props) =>
    props.display === "body" && body}
  ${(props) =>
    props.display === "detail" && detail}
  ${(props) =>
    props.display === "label" && label}
  ${(props) =>
    props.display === "code" && code}
  
  color: ${(props) =>
    props.theme.colors[props.color || "baseWhite"]};
  text-decoration: none;
  ${(props) => props.gradient && gradient};
  ${(props) => props.bold && bold}
  ${(props) => props.nowrap && nowrap}
`;

const bold = css`
  font-weight: bold;
`;

const nowrap = css`
  white-space: nowrap;
`;

const gradient = css<Props>`
  background: ${(props) =>
    props.theme.gradients[props.gradient || "brandGradient"]};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const display = css`
  font-weight: bold;
  font-size: 50px;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

const h1 = css`
  font-weight: bold;
  font-size: 40px;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

const h2 = css`
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

export const h3 = css`
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

const body = css`
  font-weight: normal;
  font-size: 15px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

const detail = css`
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.28em;
  text-transform: uppercase;
  font-family: "HelveticaNowDisplay", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

export const label = css`
  font-weight: bold;
  font-size: 15px;
  letter-spacing: -0.02em;
  font-family: "HelveticaNowText", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
`;

export const code = css`
  font-size: 12px;
  font-family: "HelveticaNowText", sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.02em;
`;
