import { createGlobalStyle } from "styled-components";
import { cssReset } from "./helpers/cssReset";
import "rc-slider/assets/index.css";
import "../../assets/fonts/stylesheet.css";

export const GlobalTheme = createGlobalStyle<any>`
  ${cssReset}

  *, *:after, *:before {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    overflow-x: hidden;
  }
  
  body {
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: 300;
    background-color: ${(props) => props.theme.colors.baseBlack};
    color: white;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    &:focus {
      outline: none;
    }
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  ::selection {
    background-color: ${(props) => props.theme.colors.brandPurple};
    color: ${(props) => props.theme.colors.baseWhite};
    text-decoration: underline;
  }

  /* Don't scroll screen on touch circles */
  .easing-editor {
    touch-action: none;
  }
`;
