// @ts-nocheck

const hypot = (x, y) => {
  let a = Math.abs(x);
  let b = Math.abs(y);

  if (a < 3000 && b < 3000) {
    return Math.sqrt(a * a + b * b);
  }

  if (a < b) {
    a = b;
    b = x / y;
  } else {
    b = y / x;
  }
  return a * Math.sqrt(1 + b * b);
};

const parse = (a, b) => {
  const z = { re: 0, im: 0 };

  if (a == null) {
    z.re = z.im = 0;
  } else if (b != null) {
    z.re = a;
    z.im = b;
  } else {
    switch (typeof a) {
      case "object":
        z.im = a.im;
        z.re = a.re;
        break;
      case "number":
        z.im = 0;
        z.re = a;
        break;

      default:
        throw Error("Wrong params in Complex constructor");
    }
  }

  return z;
};

function Complex(a, b) {
  if (!(this instanceof Complex)) {
    return new Complex(a, b);
  }

  const z = parse(a, b);

  this.re = z.re;
  this.im = z.im;
}

Complex.prototype = {
  re: 0,
  im: 0,

  add: function (a) {
    const z = new Complex(a);

    return new Complex(this.re + z.re, this.im + z.im);
  },

  mul: function (a) {
    const z = new Complex(a);

    return new Complex(
      this.re * z.re - this.im * z.im,
      this.re * z.im + this.im * z.re
    );
  },

  div: function (n) {
    const z = new Complex(n);

    const a = this.re;
    const b = this.im;

    const c = z.re;
    const d = z.im;
    let t;
    let x;

    if (d === 0) {
      // Divisor is real
      return new Complex(a / c, b / c);
    }

    if (Math.abs(c) < Math.abs(d)) {
      x = c / d;
      t = c * x + d;

      return new Complex((a * x + b) / t, (b * x - a) / t);
    }

    x = d / c;
    t = d * x + c;

    return new Complex((a + b * x) / t, (b - a * x) / t);
  },

  sqrt: function () {
    const a = this.re;
    const b = this.im;
    const r = this.abs();

    let re;
    let im;

    if (a >= 0) {
      if (b === 0) {
        return new Complex(Math.sqrt(a), 0);
      }

      re = 0.5 * Math.sqrt(2.0 * (r + a));
    } else {
      re = Math.abs(b) / Math.sqrt(2 * (r - a));
    }

    if (a <= 0) {
      im = 0.5 * Math.sqrt(2.0 * (r - a));
    } else {
      im = Math.abs(b) / Math.sqrt(2 * (r + a));
    }

    return new Complex(re, b < 0 ? -im : im);
  },

  exp: function () {
    const tmp = Math.exp(this.re);

    return new Complex(tmp * Math.cos(this.im), tmp * Math.sin(this.im));
  },

  abs: function () {
    return hypot(this.re, this.im);
  },

  valueOf: function () {
    if (this.im === 0) {
      return this.re;
    }
    return null;
  },

  isZero: function () {
    return this.re === 0 && this.im === 0;
  },

  isInfinite: function () {
    return !(this.isNaN() || this.isFinite());
  },

  isNaN: function () {
    return isNaN(this.re) || isNaN(this.im);
  },

  isFinite: function () {
    return isFinite(this.re) && isFinite(this.im);
  },
};

export const spring = (tension, friction, endPosition, startingVelocity) => (
  t
) => {
  const lsr = Complex(friction * friction - 4 * tension).sqrt();

  const m = Complex(-1).div(2).div(lsr);

  const z1 = Complex(2 * startingVelocity).mul(
    lsr.mul(-1).add(-friction).mul(t).mul(0.5).exp()
  );

  const z2 = Complex(-2 * startingVelocity).mul(
    lsr.add(-friction).mul(t).mul(0.5).exp()
  );

  const a = Complex(-friction)
    .mul(endPosition)
    .mul(lsr.mul(-1).add(-friction).mul(t).mul(0.5).exp());

  const b = Complex(friction)
    .mul(endPosition)
    .mul(lsr.add(-friction).mul(t).mul(0.5).exp());

  const c = lsr
    .mul(endPosition)
    .mul(lsr.mul(-1).add(-friction).mul(t).mul(0.5).exp());
  const d = lsr.mul(endPosition).mul(lsr.add(-friction).mul(t).mul(0.5).exp());

  const f = lsr.mul(endPosition).mul(-2);

  const res = Complex(0)
    .add(z1)
    .add(z2)
    .add(a)
    .add(b)
    .add(c)
    .add(d)
    .add(f)
    .mul(m);

  return res.valueOf();
};

export const presets = {
  default: { tension: 170, friction: 26 },
  gentle: { tension: 120, friction: 14 },
  wobbly: { tension: 180, friction: 12 },
  stiff: { tension: 210, friction: 20 },
  slow: { tension: 280, friction: 60 },
  molasses: { tension: 280, friction: 120 },
};

const transformProperties = [
  "translateY",
  "translateX",
  "scale",
  "scaleX",
  "scaleY",
  "rotate",
  "rotateX",
  "rotateY",
  "rotateZ",
];

const getSpringValue = ({
  from,
  to,
  tension,
  friction,
  startingVelocity = 0,
  t,
}) => {
  const f = spring(tension, friction, to - from, startingVelocity);

  return from + f(t);
};

export const generateKeyframes = (springs, { time = 1 } = {}) => {
  const otherSprings = springs.filter(
    (s) => !transformProperties.includes(s.property)
  );

  const transformSprings = springs.filter((s) =>
    transformProperties.includes(s.property)
  );

  let keyframesString = "";
  for (let i = 0; i <= 100; i++) {
    keyframesString = keyframesString + ` ${i}% { `;

    if (transformSprings.length > 0) {
      keyframesString = keyframesString + `transform: `;
      for (let j = 0; j < transformSprings.length; j++) {
        const { property, unit = "" } = transformSprings[j];

        const t = (i * time) / 100;
        const springValue = getSpringValue({
          ...transformSprings[j],
          t,
        });

        keyframesString =
          keyframesString + `${property}(${springValue}${unit})`;
      }

      keyframesString = keyframesString + ";";
    }

    for (let j = 0; j < otherSprings.length; j++) {
      const { property, unit = "" } = otherSprings[j];

      const t = (i * time) / 100;
      const springValue = getSpringValue({
        ...otherSprings[j],
        t,
      });

      keyframesString = keyframesString + `${property}: ${springValue}${unit};`;
    }

    keyframesString = keyframesString + ` }`;
    if (i < 100) {
      keyframesString = keyframesString + "\n";
    }
  }

  return keyframesString;
};
