import React from "react";
import { styled } from "../UI/helpers/theme";

import { ReactComponent as GradientDefs } from "../../assets/svg/gradient.svg";
import { TopNav } from "./TopNav";
import { Footer } from "./Footer";

type Props = {
  children: any;
};

export const BaseLayout = ({ children }: Props) => {
  return (
    <BaseLayoutWrapper>
      <TopNav />
      {children}
      <Footer />
      <GradientDefs />
    </BaseLayoutWrapper>
  );
};

const BaseLayoutWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  ${(props) => props.theme.screen.mobile} {
    padding: 0 20px;
  }
`;
