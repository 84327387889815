import React, { memo } from "react";
import get from "lodash/get";

import { Copy } from "../../UI/Copy";
import { EASING_MAP, LABELS } from "../../../constants";
import { Button } from "../../UI/Button";
import { styled } from "../../UI/helpers/theme";

type Props = {
  kind: string;
  benzier: string;
  onClick: (value: string) => void;
  title: string;
};

export const BezierControl = memo(
  ({ benzier, kind, title, onClick }: Props) => {
    return (
      <BezierWrapper>
        <Copy display="detail" type="div" color="baseGrey1" className="title">
          {title}
        </Copy>
        {Object.keys(get(EASING_MAP, kind)).map((item) => (
          <Button
            key={item}
            icon={kind === "linear" ? `ease_${item}` : `${kind}_${item}`}
            active={item === benzier}
            disabled={kind === "linear"}
            onClick={() => onClick(item)}
            gradient
          >
            {get(LABELS, item)}
          </Button>
        ))}
      </BezierWrapper>
    );
  }
);

const BezierWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 16px;
  margin-right: 16px;
  align-items: baseline;
  border-right: 1px solid ${(props) => props.theme.colors.baseGrey3};
  padding-top: 30px;
  position: relative;

  .title {
    top: 0;
    position: absolute;
  }

  ${(props) => props.theme.screen.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    border-right: 0;
    button {
      margin-right: 2px;
    }
  }
`;
