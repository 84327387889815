import React, { memo } from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";

import { Copy } from "../../UI/Copy";
import { EASING_MAP } from "../../../constants";
import { Button } from "../../UI/Button";
import { styled } from "../../UI/helpers/theme";

type Props = {
  kind: string;
  benzier: string;
  style: string;
  onClick: (value: string) => void;
  title: string;
};

export const StyleControl = memo(
  ({ kind, benzier, style, onClick, title }: Props) => {
    benzier = benzier || "in";

    return (
      <StyletionsWrapper kind={kind}>
        <Copy display="detail" type="div" color="baseGrey1" className="title">
          {title}
        </Copy>
        {Object.values(get(EASING_MAP, [kind, benzier], [])).map(
          (item: any) => (
            <Button
              key={item}
              icon={
                kind === "linear"
                  ? `ease_${benzier}_${item}`
                  : `${kind}_${benzier}_${item}`
              }
              onClick={() => onClick(item)}
              disabled={kind === "linear"}
              active={item === style}
              gradient
            >
              {upperFirst(item)}
            </Button>
          )
        )}
      </StyletionsWrapper>
    );
  }
);

const StyletionsWrapper = styled.div<{ kind: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 30px;
  position: relative;
  margin-bottom: auto;

  button {
    margin-right: 2px !important;
  }

  .title {
    top: 0;
    position: absolute;
  }
`;
