import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

if (!("PointerEvent" in window)) {
  // @ts-ignore
  import("@wessberg/pointer-events");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
