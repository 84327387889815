import React, { useEffect, useState } from "react";
import { styled } from "../../UI/helpers/theme";
import { generateCurvePoints } from "../../../helpers/bounce";
import { SpringGrid } from "../SpringEditor/SpringGrid";

type Props = {
  curveColor: string;
  curveWidth: number;
  value: any;
  benzier: string;
  withGrid: boolean;
};

export const BounceCurve = ({
  value: [bounces, stiffness],
  benzier,
  curveColor,
  curveWidth,
  withGrid,
}: Props) => {
  const [points, setPoints] = useState("");
  useEffect(() => {
    const elements = generateCurvePoints({
      bounces,
      stiffness,
      benzier,
    });
    setPoints(elements.join(", "));
  }, [bounces, stiffness, benzier]);

  return (
    <Wrapper>
      <svg width="300" height="300">
        <polyline
          id="polyline"
          fill="none"
          stroke={curveColor}
          strokeWidth={curveWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
          points={points}
        />
      </svg>
      {withGrid && <SpringGrid className="grid" />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  user-select: none;
  border: 1px solid #292e3d;
  overflow: visible;
  margin-bottom: 20px;
  height: 300px;

  .grid {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;
