import React, { useMemo } from "react";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";

import { styled } from "../UI/helpers/theme";
import { Copy } from "../UI/Copy";

import { KindControl } from "./RecipeComponents/KindControl";
import { BezierControl } from "./RecipeComponents/BezierControl";
import { StyleControl } from "./RecipeComponents/StyleControl";

import {
  LABELS,
  ADVANCED_STYLES_DESC,
  BEGINNER_STYLES_DESC,
} from "../../constants";
import { BlockHeading } from "../Base/BlockHeading";
import { isSpring, isBounce } from "../../helpers/common";

type Props = {
  kind: string;
  benzier: string;
  style: string;
  onSetKind: (value: string) => void;
  onSetBenzier: (value: string) => void;
  onSetStyle: (value: string) => void;
  advancedMode: boolean;
};

export const Recipe = ({
  kind,
  benzier,
  style,
  onSetStyle,
  onSetBenzier,
  onSetKind,
  advancedMode,
}: Props) => {
  const modeTitles = useMemo(
    () => ({
      styleDesc:
        get(
          advancedMode ? ADVANCED_STYLES_DESC : BEGINNER_STYLES_DESC,
          style
        ) || "No Easing ☠️",
      bezierControlTitle: advancedMode ? "BÉZIER" : "CURVE",
      styleControlTitle: advancedMode ? "STYLE" : "POWER",
    }),
    [advancedMode, style]
  );

  const titles = useMemo(
    () => ({
      heading: isSpring(kind)
        ? upperFirst(style)
        : get(LABELS, benzier || kind),
      styleTitle: isSpring(kind)
        ? get(LABELS, kind)
        : isBounce(kind)
        ? `${upperFirst(style)} ${get(LABELS, kind)}`
        : upperFirst(style),
    }),
    [kind, style, benzier]
  );

  return (
    <RecipeWrapper>
      <BlockHeading title="1. Recipe" />
      <HeadingWrapper>
        <Copy display="h1" color="baseWhite">
          {titles.heading}
        </Copy>
        <Copy display="h1" color="baseWhite">
          {titles.styleTitle}
        </Copy>
        <Copy display="body" color="baseGrey2" nowrap className="desc">
          ({modeTitles.styleDesc})
        </Copy>
      </HeadingWrapper>
      <ControlWrapper>
        <KindControl title="KIND" kind={kind} onClick={onSetKind} />
        {!isSpring(kind) && (
          <BezierControl
            kind={kind}
            benzier={benzier}
            onClick={onSetBenzier}
            title={modeTitles.bezierControlTitle}
          />
        )}
        <StyleControl
          kind={kind}
          style={style}
          benzier={benzier}
          onClick={onSetStyle}
          title={modeTitles.styleControlTitle}
        />
      </ControlWrapper>
    </RecipeWrapper>
  );
};

const RecipeWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  button {
    margin-bottom: 10px;
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  span {
    margin-right: 10px;
  }

  ${(props) => props.theme.screen.mobile} {
    .desc {
      width: 100%;
      display: block;
      margin-top: 10px;
    }
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.screen.tablet} {
    flex-direction: column;
  }
`;
