import { theme } from "./theme";

// font-size: 12px;
// font-family: "HelveticaNowText", sans-serif;
// font-weight: normal;
// font-style: normal;
// letter-spacing: -0.02em;

export const syntaxTheme = {
  'code[class*="language-"]': {
    color: theme.colors.baseGrey1,
    background: "none",
    textShadow: "0 -.1em .2em black",
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    fontSize: "12px",
    textAlign: "left",
    whiteSpace: "pre-wrap",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
  },
  'pre[class*="language-"]': {
    color: theme.colors.baseGrey1,
    background: "transparent",
    textShadow: "none",
    fontFamily: theme.fontFamily,
    fontSize: "1em",
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    wordWrap: "normal",
    lineHeight: "1",
    MozTabSize: "4",
    OTabSize: "4",
    tabSize: "4",
    WebkitHyphens: "none",
    MozHyphens: "none",
    msHyphens: "none",
    hyphens: "none",
    padding: "20px",
    margin: ".5em 0",
    overflow: "auto",
    border: `1px solid ${theme.colors.baseGrey3}`,
    borderRadius: "5px",
    boxShadow: "none",
    maxHeight: "700px",
  },
  ':not(pre) > code[class*="language-"]': {
    background: "hsl(30, 20%, 25%)",
    padding: ".15em .2em .05em",
    borderRadius: ".3em",
    border: ".13em solid hsl(30, 20%, 40%)",
    boxShadow: "1px 1px .3em -.1em black inset",
    whiteSpace: "normal",
  },
  comment: {
    color: theme.colors.baseGrey2,
  },
  prolog: {
    color: theme.colors.baseGrey2,
  },
  doctype: {
    color: theme.colors.baseGrey2,
  },
  cdata: {
    color: theme.colors.baseGrey2,
  },
  punctuation: {
    Opacity: ".7",
  },
  namespace: {
    Opacity: ".7",
  },
  property: {
    color: theme.colors.baseGrey1,
  },
  tag: {
    color: theme.colors.baseGrey1,
  },
  boolean: {
    color: theme.colors.baseGrey1,
  },
  number: {
    color: theme.colors.brandPink,
  },
  unit: {
    color: theme.colors.baseGrey1,
  },
  constant: {
    color: theme.colors.baseGrey1,
  },
  symbol: {
    color: theme.colors.baseGrey1,
  },
  selector: {
    color: theme.colors.baseWhite,
  },
  "attr-name": {
    color: theme.colors.baseWhite,
  },
  string: {
    color: theme.colors.baseWhite,
  },
  char: {
    color: theme.colors.baseWhite,
  },
  builtin: {
    color: theme.colors.baseWhite,
  },
  inserted: {
    color: theme.colors.baseWhite,
  },
  operator: {
    color: theme.colors.baseWhite,
  },
  entity: {
    color: theme.colors.baseWhite,
    cursor: "help",
  },
  url: {
    color: theme.colors.baseWhite,
  },
  ".language-css .token.string": {
    color: theme.colors.baseWhite,
  },
  ".style .token.string": {
    color: theme.colors.baseWhite,
  },
  variable: {
    color: theme.colors.baseWhite,
  },
  function: {
    color: theme.colors.brandBlue,
  },
  atrule: {
    color: theme.colors.brandBlue,
  },
  "attr-value": {
    color: theme.colors.brandBlue,
  },
  keyword: {
    color: theme.colors.baseGrey1,
  },
  regex: {
    color: "#e90",
  },
  important: {
    color: "#e90",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  deleted: {
    color: "red",
  },
};

// width: 100%;
// resize: none;
// padding: 20px;
// background-color: transparent;
// border: 1px solid ${(props) => props.theme.colors.baseGrey3};
// border-radius: 5px;
// color: ${(props) => props.theme.colors.brandBlue};
// font-style: normal;
// font-weight: 400;
// line-height: 16px;
// letter-spacing: 0.01em;
// min-height: 200px;
