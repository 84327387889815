import { createEvent } from "effector";
import { LoopTypes, AlignTypes } from "./contants";

export const setAdvancedMode = createEvent("SET_ADVANCED_MODE");
export const setBeginnerMode = createEvent("SET_BEGINNER_MODE");

export const setCurve = createEvent<number[]>("SET_CURVE");
export const draggingStart = createEvent("SET_START");
export const draggingEnd = createEvent("SET_END");

export const setKind = createEvent<string>("SET_KIND");
export const setBenzier = createEvent<string>("SET_BENZIER");
export const setStyle = createEvent<string>("SET_STYLE");

export const setAnimation = createEvent<string>("SET_ANIMATION");
export const setLoop = createEvent<LoopTypes>("SET_LOOP");
export const setAlign = createEvent<AlignTypes>("SET_ALIGN");
export const setMs = createEvent<number>("SET_MS");

export const setLanguage = createEvent<string>();
export const setButtonView = createEvent();
export const setCodeView = createEvent();

export const setKeyframe = createEvent<any>();
